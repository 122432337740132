/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { ChevronLeft, ChevronRight, Gear, PencilSquare } from 'react-bootstrap-icons'

import './top-bar-container.scss'
import './button.scss'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { openSettingsModal, openSelectOrCreateIdentityModal, openDraftsModal } from '../slices/modal'
import { toggleSearchPanel } from '../slices/panels'
import { AllShapes } from './AllShapes'

interface Props {
  leftButtons?: React.ReactNode
  centerButtons?: React.ReactNode
  rightButtons?: React.ReactNode
}

export const TopBarContainer = ({ leftButtons, centerButtons, rightButtons }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const searchPanelIsOpen = useAppSelector(({ panels }) => panels.searchPanelIsOpen)

  function handleSelectIdentityButtonClick (e: React.MouseEvent): void {
    dispatch(openSelectOrCreateIdentityModal())
  }

  function handleSettingsButtonClick (e: React.MouseEvent): void {
    dispatch(openSettingsModal())
  }

  function handleDraftsButtonClick (e: React.MouseEvent): void {
    dispatch(openDraftsModal())
  }

  function handleToggleSearchPanelClick (e: React.MouseEvent): void {
    dispatch(toggleSearchPanel())
  }

  return (
    <div className='top-bar-container'>
      <div className='button-group'>
        {leftButtons}
      </div>
      <div className='button-group'>
        {centerButtons}
      </div>
      <div className='button-group'>
        {rightButtons}
        <button className='button' onClick={handleDraftsButtonClick} title='Drafts'>
          <PencilSquare className='bi' />
        </button>
        <button className='button' onClick={handleSelectIdentityButtonClick} title='Select identity'>
          <AllShapes shape='people' />
        </button>
        <button className='button' onClick={handleSettingsButtonClick} title='Settings'>
          <Gear className='bi' />
        </button>
        <button className='button' onClick={handleToggleSearchPanelClick} title='Toggle search panel'>
          {searchPanelIsOpen
            ? <ChevronLeft className='bi' />
            : <ChevronRight className='bi' />}
        </button>
      </div>
    </div>
  )
}
