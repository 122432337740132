/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { Search } from 'react-bootstrap-icons'

import './search.scss'
import './button.scss'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { setSearchQuery, searchMessages } from '../slices/search'

import { Banner } from './Banner'

export const SearchForm = (): ReactElement => {
  const dispatch = useAppDispatch()

  const searchAsPeerURL = useAppSelector(({ search }) => search.searchAsPeerURL)
  const searchQuery = useAppSelector(({ search }) => search.searchQuery)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSearchQuery({ searchQuery: e.target.value }))
  }

  const handleSubmit = (e: React.FormEvent): void => {
    dispatch(searchMessages())
    e.preventDefault()
  }

  const searchAsPeer = searchAsPeerURL !== undefined
    ? (
      <>
        As <Banner authorURL={searchAsPeerURL} />
      </>
      )
    : undefined

  return (
    <>
      <form className='search' onSubmit={handleSubmit}>
        <div className='search-bar'>
          <input
            type='text'
            value={searchQuery}
            onChange={handleChange}
            placeholder='Search messages'
            alt='Search for published messages'
          />
          <button className='button' type='submit' title='Search'>
            <Search className='bi' />
          </button>
        </div>
        {searchAsPeer}
      </form>
    </>
  )
}
