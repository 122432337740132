/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { Middleware } from '@reduxjs/toolkit'
import { helpers } from 'ushin-db'

import { RootState } from '../slices'
import { setThresholdSuccess, setMaxHopsSuccess, syncTrustParamsAndSearchParamsSuccess } from '../slices/trust'
import { getTrustLabel } from '../utils/trustHelpers'

export const syncQueryStringMiddleware: Middleware<{}, RootState> = storeAPI => next => async action => {
  if (setThresholdSuccess.match(action)) {
    const { trustArea, threshold, searchParams, setSearchParams } = action.payload
    searchParams.set(`${trustArea as string}threshold`, getTrustLabel(threshold))

    searchParams.sort()

    setSearchParams(searchParams, { replace: 'true' })
  } else if (setMaxHopsSuccess.match(action)) {
    const { trustArea, maxHops, searchParams, setSearchParams } = action.payload
    searchParams.set(`${trustArea as string}maxhops`, maxHops)

    searchParams.sort()

    setSearchParams(searchParams, { replace: 'true' })
  } else if (syncTrustParamsAndSearchParamsSuccess.match(action)) {
    const { authorURL, searchParams, setSearchParams, authorTrustParams } = action.payload
    const { maxHops, threshold } = authorTrustParams[authorURL]

    searchParams.set(`${helpers.SOURCE as string}maxhops`, maxHops[helpers.SOURCE])
    searchParams.set(`${helpers.SOURCE as string}threshold`, getTrustLabel(threshold[helpers.SOURCE]))
    searchParams.set(`${helpers.BLOCKER as string}maxhops`, maxHops[helpers.BLOCKER])
    searchParams.set(`${helpers.BLOCKER as string}threshold`, getTrustLabel(threshold[helpers.BLOCKER]))

    searchParams.sort()

    setSearchParams(searchParams, { replace: 'true' })
  }

  return next(action)
}
