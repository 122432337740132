/*
  Copyright (C) 2020-2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthorStore, MessageStore, PointStore } from 'ushin-db'

interface SearchState {
  searchQuery: string
  searchAsPeerURL?: string
  results: string[]
  hasSearched: boolean
  continueSearchMessagesPossible: boolean
  searchResultsDone: boolean
}

const initialSearchState: SearchState = {
  searchQuery: '',
  results: [],
  hasSearched: false,
  continueSearchMessagesPossible: false,
  searchResultsDone: false
}

const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    setSearchAsPeerURL: (state, action: PayloadAction<{
      url: string
    }>) => {
      state.searchAsPeerURL = action.payload.url
    },

    setSearchQuery: (state, action: PayloadAction<{
      searchQuery: string
    }>) => {
      state.searchQuery = action.payload.searchQuery
    },

    searchMessages: (state) => {
      state.results = []
      state.hasSearched = true
      state.continueSearchMessagesPossible = false
      state.searchResultsDone = false
    },

    continueSearchMessages: (state) => state,

    allowContinueSearchMessages: (state) => {
      state.continueSearchMessagesPossible = true
    },

    doneSearchMessages: (state) => {
      state.searchResultsDone = true
      state.continueSearchMessagesPossible = false
    },

    addSearchResultMessage: (state, action: PayloadAction<{
      result: string
      messages: MessageStore
      authorStore: AuthorStore
      pointStore: PointStore
    }>) => {
      state.results.push(action.payload.result.url)
    }
  }
})

export const {
  setSearchAsPeerURL,
  setSearchQuery,
  searchMessages,
  continueSearchMessages,
  allowContinueSearchMessages,
  doneSearchMessages,
  addSearchResultMessage
} = searchSlice.actions
export default searchSlice.reducer
