/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
 */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { helpers, TrustArea, TrustType } from 'ushin-db'
import { Link, useSearchParams } from 'react-router-dom'
import { DagreReact, EdgeOptions, Node, NodeOptions, NodeTextLabel, MarkerProps, Size } from 'dagre-reactjs'
import { ShapeInfo, Intersection } from 'kld-intersections'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import AutoSizer from 'react-virtualized-auto-sizer'

import './peer-graph.scss'

import { useAppSelector } from '../hooks/useRedux'
import { TrustState } from '../slices/trust'

import { PeerGraphToolbar } from './PeerGraphToolbar'

import { RecursivePartial } from '../utils/types'
import { removeHyperPrefix } from '../dataModels/pointUtils'
import { getTrustLabel } from '../utils/trustHelpers'
import { deleteSearchParamByKeyValue } from '../utils/searchParamsHelpers'

const { SOURCE, BLOCKER, BLOCKED } = helpers

export interface SearchParamsOpts {
  sourceThreshold: number
  sourceMaxHops: number
  blockerThreshold: number
  blockerMaxHops: number
  toAuthorURLs: string[]
  showSources: boolean
  showBlockers: boolean
  showBlocked: boolean
  showAllBlocked: boolean
  showShortestPath: boolean
  showFunneled: boolean
}

interface Props extends SearchParamsOpts {
  authorURL: string
}

const intersectPath = (node: NodeOptions, point: Point, pathString: string): Point => {
  if (node.x === undefined || node.y === undefined) throw new Error('node lacked x or y value')
  const path = ShapeInfo.path(pathString)
  const line = ShapeInfo.line([point.x - node.x, point.y - node.y], [0, 0])
  const intersections2 = Intersection.intersect(line, path)

  if (intersections2.points.length > 0) {
    return {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      x: intersections2.points[0].x + node.x,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      y: intersections2.points[0].y + node.y
    }
  }
  return { x: node.y, y: node.y }
}

const gapBetweenBannerAndEdge = 12
const amplitude = 3
function calculatePointsFromBannerSize ({ width, height }: Size, forIntersection: boolean): {
  xMax: number
  yMax: number
  xMin: number
  yMin: number
  peakWidth: number
  topPeakHeight: number
  bottomPeakHeight: number
  inflectionWidth: number
  troughWidth: number
  topTroughHeight: number
  bottomTroughHeight: number
} {
  const xMax = forIntersection ? (width + gapBetweenBannerAndEdge) / 2 : width / 2
  const yMax = forIntersection ? (height + gapBetweenBannerAndEdge) / 2 : height / 2
  const xMin = -xMax
  const yMin = -yMax

  return {
    xMax,
    yMax,
    xMin,
    yMin,
    peakWidth: xMin / 2,
    topPeakHeight: yMin - amplitude,
    bottomPeakHeight: yMax - amplitude,
    inflectionWidth: 0,
    troughWidth: xMax / 2,
    topTroughHeight: yMin + amplitude,
    bottomTroughHeight: yMax + amplitude
  }
}

export const calculateBannerPath = (innerSize: Size, forIntersection: boolean): string => {
  const { xMax, yMax, xMin, yMin, peakWidth, topPeakHeight, inflectionWidth, troughWidth, bottomTroughHeight } = calculatePointsFromBannerSize(innerSize, forIntersection)
  return `M ${xMin},${yMin} Q ${peakWidth} ${topPeakHeight} ${inflectionWidth} ${yMin} T ${xMax} ${yMin} L ${xMax} ${yMax} Q ${troughWidth} ${bottomTroughHeight} ${inflectionWidth} ${yMax} T ${xMin} ${yMax} Z`
}

interface NodeMeta {
  source: boolean
  blocker: boolean
  blocked: boolean
  funneled: boolean
}

const BannerNodeLabel = ({ node, innerSize }): BannerNodeLabelProps => {
  const [searchParams, setSearchParams] = useSearchParams()

  if (node === undefined || innerSize?.width === undefined || innerSize.height === undefined) {
    return null
  }

  const { source, blocker, blocked, funneled } = node.meta as NodeMeta

  const handleFunnelClick = (e: React.FormEvent): void => {
    if (funneled) {
      deleteSearchParamByKeyValue(searchParams, 'topeer', removeHyperPrefix(node.id))
    } else {
      searchParams.append('topeer', removeHyperPrefix(node.id))
    }
    setSearchParams(searchParams)
  }

  const path = calculateBannerPath(innerSize, false)

  const { xMax, yMax, xMin, yMin, peakWidth, topPeakHeight, bottomPeakHeight, inflectionWidth, troughWidth, topTroughHeight, bottomTroughHeight } = calculatePointsFromBannerSize(innerSize, false)

  const leftHalfPath = `M ${xMin},${yMin} Q ${peakWidth} ${topPeakHeight} ${inflectionWidth} ${yMin} L ${inflectionWidth} ${yMax} Q ${peakWidth} ${bottomPeakHeight} ${xMin} ${yMax} Z`
  const rightHalfPath = `M ${inflectionWidth},${yMin} Q ${troughWidth} ${topTroughHeight} ${xMax} ${yMin} L ${xMax} ${yMax} Q ${troughWidth} ${bottomTroughHeight} ${inflectionWidth} ${yMax} Z`

  const funnelOutlinePath = `M ${xMax},${yMin} L ${xMax + 22},${yMin} L ${xMax + 22},${yMax} L ${xMax},${yMax}`
  const funnelPath = `M ${xMax + 2},${yMin} L ${xMax + 22},${yMin} L ${xMax + 12},${0} L ${xMax + 12},${yMax} L ${xMax + 10},${yMax} L ${xMax + 10},${0} Z`

  let nodeGroupClassName = 'node-group'
  if (source) nodeGroupClassName += ' source'
  if (blocker) nodeGroupClassName += ' blocker'
  if (blocked) nodeGroupClassName += ' blocked'

  let funnelPathClassName = 'funnel-path'
  if (funneled) funnelPathClassName += ' funneled'

  return (
    <g className={nodeGroupClassName}>
      <path className='left-half' d={leftHalfPath} />
      <path className='right-half' d={rightHalfPath} />
      <Link to={`/${removeHyperPrefix(node.id)}`}>
        <path className='banner-path' style={node.styles.shape.styles ?? {}} d={path} />
      </Link>
      <path className={funnelPathClassName} d={funnelPath} />
      <path className='funnel-outline-path' d={funnelOutlinePath} onClick={handleFunnelClick} />
    </g>
  )
}

const CustomMarker = ({ markerId }: MarkerProps): ReactElement => {
  return (
    <marker
      id={markerId}
      viewBox='0 0 6 18'
      refX='3'
      refY='9'
      markerUnits='strokeWidth'
      markerWidth='3'
      markerHeight='3'
      orient='auto'
    >
      <path
        // This path draws an arrow pointing right, which is then oriented based on the angle of the edge
        d='M 0 0 L 6 9 L 0 18 L 2 12 L 3 12 L 3 6 L 2 6 Z'
      />
    </marker>
  )
}

function makeEdgesFromURLHopsMapping (
  urlHopsMapping: URLHopsMapping,
  trust: TrustState,
  trustArea: TrustArea,
  threshold: number,
  showShortestPath: boolean
): EdgeWithoutMeta[] {
  const edges = []

  for (const fromURL in urlHopsMapping) {
    const { weights } = trust[fromURL].trustInfo[trustArea]
    for (const toURL in weights) {
      const fromURLHops = urlHopsMapping[fromURL]
      const toURLHops = urlHopsMapping[toURL]
      if (
        toURLHops !== undefined &&
        (!showShortestPath || toURLHops > fromURLHops) &&
        weights[toURL] >= threshold
      ) {
        edges.push({ fromURL, toURL, type: trustArea, weight: weights[toURL] })
      }
    }
  }

  return edges
}

function getAllEdges ({
  sourceURLHopsMapping,
  blockerURLHopsMapping,
  blockedButDirectSourceURLHopsMapping,
  indirectSourceButBlockedURLHopsMapping,
  trust,
  searchParamsOpts
}: {
  sourceURLHopsMapping: URLHopsMapping
  blockerURLHopsMapping: URLHopsMapping
  blockedButDirectSourceURLHopsMapping: URLHopsMapping
  indirectSourceButBlockedURLHopsMapping: URLHopsMapping
  trust: TrustState
  searchParamsOpts: SearchParamsOpts
}): {
    sourceEdges: EdgeWithoutMeta[]
    blockerEdges: EdgeWithoutMeta[]
    blockedEdges: EdgeWithoutMeta[]
  } {
  const { sourceThreshold, blockerThreshold, showSources, showBlockers, showBlocked, showAllBlocked, showShortestPath } = searchParamsOpts

  let includedSourceURLHopsMapping = {}
  if (showSources) includedSourceURLHopsMapping = { ...sourceURLHopsMapping, ...blockedButDirectSourceURLHopsMapping }
  // If showBlocked, also include source edges to peers which have been blocked
  if (showBlockers && showBlocked) Object.assign(includedSourceURLHopsMapping, indirectSourceButBlockedURLHopsMapping)

  const sourceEdges = makeEdgesFromURLHopsMapping(includedSourceURLHopsMapping, trust, SOURCE, sourceThreshold, showShortestPath)

  let blockerEdges = []
  const blockedEdges = []

  if (showBlockers) {
    blockerEdges = blockerEdges.concat(makeEdgesFromURLHopsMapping(blockerURLHopsMapping, trust, BLOCKER, blockerThreshold, showShortestPath))

    for (const fromURL in blockerURLHopsMapping) {
      // The logic for adding blocked edges is inside the blockerURLHopsMapping for..in loop, since blocked edges must always go from a blocker to a blocked peer
      if (showBlocked) {
        const { distrusted } = trust[fromURL].trustInfo[SOURCE]

        for (const toURL of distrusted) {
          if (
            showAllBlocked || // Don't bother checking that the blocked peer is otherwise included
            (showSources && includedSourceURLHopsMapping[toURL] !== undefined) ||
            indirectSourceButBlockedURLHopsMapping[toURL] !== undefined
          ) {
            blockedEdges.push({ fromURL, toURL, type: BLOCKED })
          }
        }
      }
    }
  }

  return { sourceEdges, blockerEdges, blockedEdges }
}

interface EdgeWithoutMeta {
  fromURL: string
  toURL: string
  type: SOURCE | BLOCKER | BLOCKED
  weight: number
}

function getFinalEdges ({ authorURL, sourceURLHopsMapping, blockerURLHopsMapping, blockedButDirectSourceURLHopsMapping, indirectSourceButBlockedURLHopsMapping, trust, searchParamsOpts }: {
  authorURL: string
  sourceURLHopsMapping: URLHopsMapping
  blockerURLHopsMapping: URLHopsMapping
  blockedButDirectSourceURLHopsMapping: URLHopsMapping
  indirectSourceButBlockedURLHopsMapping: URLHopsMapping
  trust: TrustState
  searchParamsOpts: SearchParamsOpts
}): {
    finalEdgesWithoutMeta: EdgeWithoutMeta[]
  } {
  const { sourceEdges, blockerEdges, blockedEdges } = getAllEdges({
    sourceURLHopsMapping,
    blockerURLHopsMapping,
    blockedButDirectSourceURLHopsMapping,
    indirectSourceButBlockedURLHopsMapping,
    trust,
    searchParamsOpts
  })

  const { toAuthorURLs, showFunneled, showBlockers, showBlocked } = searchParamsOpts

  let finalEdgesWithoutMeta: EdgeWithoutMeta[]

  if (toAuthorURLs.length > 0 && showFunneled) {
    // Limit source and blocker edges independently of one another to ensure that edges of one type only appear if a complete path from fromURL to toURL exists for that edge type
    const { finalEdgesOfOneEdgeType: finalSourceEdges } = excludeIncompletePaths(sourceEdges, toAuthorURLs)

    // Include blocked edges if toURL is one of the toAuthorURLs
    const finalBlockedEdges = blockedEdges.filter(({ toURL }) => toAuthorURLs.includes(toURL))

    // When showBlockers and showBlocked, additionally display blocker nodes and edges which lead to any fromURL in finalBlockedEdges.
    let toBlockerAuthorURLs = toAuthorURLs
    if (showBlockers && showBlocked) {
      const peersWhoBlockToAuthorURLs = finalBlockedEdges.map(({ fromURL }) => fromURL)
      toBlockerAuthorURLs = [...new Set([...toBlockerAuthorURLs, ...peersWhoBlockToAuthorURLs])]
    }

    const { finalEdgesOfOneEdgeType: finalBlockerEdges } = excludeIncompletePaths(blockerEdges, toBlockerAuthorURLs)

    finalEdgesWithoutMeta = [...finalSourceEdges, ...finalBlockerEdges, ...finalBlockedEdges]
  } else {
    finalEdgesWithoutMeta = [...sourceEdges, ...blockerEdges, ...blockedEdges]
  }

  return { finalEdgesWithoutMeta }
}

function excludeIncompletePaths (
  edges: EdgeWithoutMeta[],
  toAuthorURLsOfOneEdgeType: string[]
): {
    finalEdgesOfOneEdgeType: EdgeWithoutMeta[]
    finalURLsOfOneEdgeType: string[]
  } {
  const finalURLsOfOneEdgeType = new Set()

  // Ensure that toAuthorURLs appear as a Node even if no path to them exists
  for (const url of toAuthorURLsOfOneEdgeType) {
    finalURLsOfOneEdgeType.add(url)
  }

  let edgesToCheck = [...edges]

  const finalEdgesOfOneEdgeType = []

  this.nextToURLs = [...toAuthorURLsOfOneEdgeType]

  while (edgesToCheck.some(({ toURL }) => this.nextToURLs.includes(toURL))) {
    const nextToURLsNow = this.nextToURLs
    this.nextToURLs = []
    for (const url of nextToURLsNow) {
      const edgesToMove = []
      for (const edge of edgesToCheck) {
        if (edge.toURL === url) {
          edgesToMove.push(edge)
          nextToURLs.push(edge.fromURL)
        }
      }

      for (const edge of edgesToMove) {
        finalEdgesOfOneEdgeType.push(edge)

        finalURLsOfOneEdgeType.add(edge.fromURL)
        finalURLsOfOneEdgeType.add(edge.toURL)
      }

      edgesToCheck = edgesToCheck.filter(edge => !edgesToMove.includes(edge))
    }
  }

  return { finalEdgesOfOneEdgeType, finalURLsOfOneEdgeType }
}

interface URLToMetaMapping {
  [url: string]: Omit<NodeMeta, 'funneled'>
}

interface URLWithMeta {
  url: string
  meta: Omit<NodeMeta, 'funneled'>
}

function getURLToMetaMappingFromEdges (finalEdgesWithoutMeta: EdgeWithoutMeta[], blockedButDirectSourceURLHopsMapping: URLHopsMapping, authorURL: string, searchParamsOpts: SearchParamsOpts): URLWithMeta[] {
  // getURLToMetaMappingFromEdges deduplicates edge urls while retaining each url's type information, i.e., source, blocker, blocked.
  const { toAuthorURLs, showFunneled, showSources, showBlockers } = searchParamsOpts

  const urlToMetaMapping: URLToMetaMapping = {}

  for (const edge of finalEdgesWithoutMeta) {
    urlToMetaMapping[edge.fromURL] ?? (urlToMetaMapping[edge.fromURL] = { source: false, blocker: false, blocked: false })
    urlToMetaMapping[edge.toURL] ?? (urlToMetaMapping[edge.toURL] = { source: false, blocker: false, blocked: false })
    const fromMetaMapping = urlToMetaMapping[edge.fromURL]
    const toMetaMapping = urlToMetaMapping[edge.toURL]

    if (edge.type === SOURCE) {
      fromMetaMapping.source = true
      toMetaMapping.source = true
    }

    if (edge.type === BLOCKER) {
      fromMetaMapping.blocker = true
      toMetaMapping.blocker = true
    }

    if (edge.type === BLOCKED) {
      fromMetaMapping.blocker = true
      if (blockedButDirectSourceURLHopsMapping[edge.toURL] === undefined) {
        toMetaMapping.source = false
        toMetaMapping.blocked = true
      }
    }
  }

  // Ensure that authorURL and possibly toAuthorURLs appear in the graph
  const ensureURLMetas = []

  if (showFunneled) {
    for (const url of toAuthorURLs) {
      // Don't add authorURL to ensureURLMetas. It will be added later.
      if (url === authorURL) continue
      // If urlToMetaMapping[url] === undefined, the node will render with no background-color.
      ensureURLMetas.push({ url, meta: urlToMetaMapping[url] })
    }
  }

  // authorURL is always a source/blocker if showSources/showBlockers, even if no source/blocker edge exists to/from author
  const authorURLMeta = { url: authorURL, meta: { source: showSources, blocker: showBlockers, blocked: false } }
  // By putting authorURL at the beginning of the array of nodes, we ensure that it appears at the top of the graph
  ensureURLMetas.unshift(authorURLMeta)

  for (const { url } of ensureURLMetas) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete urlToMetaMapping[url]
  }
  const urlMetas = Object.entries(urlToMetaMapping).map(([url, meta]) => ({ url, meta }))

  return [...ensureURLMetas, ...urlMetas]
}

export const PeerGraph = ({
  authorURL,
  sourceThreshold,
  sourceMaxHops,
  blockerThreshold,
  blockerMaxHops,
  toAuthorURLs,
  showSources,
  showBlockers,
  showBlocked,
  showAllBlocked,
  showShortestPath,
  showFunneled
}: Props): ReactElement => {
  const searchParamsOpts: SearchParamsOpts = { sourceThreshold, sourceMaxHops, blockerThreshold, blockerMaxHops, toAuthorURLs, showSources, showBlockers, showBlocked, showAllBlocked, showShortestPath, showFunneled }

  const trust = useAppSelector(({ trust }) => trust)
  const authorsByURL = useAppSelector(({ authors }) => authors.byURL)

  const author = authorsByURL[authorURL]
  const { sourceURLHopsMapping, blockerURLHopsMapping, blockedButDirectSourceURLHopsMapping, indirectSourceButBlockedURLHopsMapping } = trust[authorURL]

  function generateNode ({ url, meta }: URLWithMeta): RecursivePartial<NodeOptions> {
    const funneled = toAuthorURLs.includes(url)

    return {
      id: url,
      label: authorsByURL[url].name,
      shape: 'banner',
      styles: {
        node: {
          padding: {
            top: 2,
            right: 4,
            bottom: 4,
            left: 2
          }
        },
        shape: {
          styles: {
            stroke: authorsByURL[url].color
          }
        },
        label: {
          className: 'node-label'
        }
      },
      meta: { ...meta, funneled }
    }
  }

  let name = 0 // For assigning unique keys to edges between the same nodes, i.e. a source edge and blocker edge from A to B

  function generateEdge ({ fromURL, toURL, type, weight }: {
    fromURL: string
    toURL: string
    type: TrustType
    weight?: number
  }): RecursivePartial<EdgeOptions> {
    let className = `edge ${type}`
    if (weight !== undefined) className += ` ${getTrustLabel(weight)}`

    return {
      from: fromURL,
      to: toURL,
      name: name++,
      styles: {
        edge: {
          styles: {},
          className
        }
      },
      markerType: 'custom'
    }
  }

  const { finalEdgesWithoutMeta } = getFinalEdges({
    authorURL,
    sourceURLHopsMapping,
    blockerURLHopsMapping,
    blockedButDirectSourceURLHopsMapping,
    indirectSourceButBlockedURLHopsMapping,
    trust,
    searchParamsOpts
  })

  // Get nodes from edges
  const urlsWithMeta = getURLToMetaMappingFromEdges(finalEdgesWithoutMeta, blockedButDirectSourceURLHopsMapping, authorURL, searchParamsOpts)

  // TODO: When a blocked peer that's not also boosted, i.e., an "irrelevant peer", is funneled, if 'all blocked' is unchecked, the peer disappears. The peer should not disappear?
  // TODO: Perhaps we want to automatically display "all blocked" when "source" is unchecked? This would make sense since when no source edges are displayed, all blocked peers are "irrelevant".

  const finalNodes = urlsWithMeta.map(urlWithMeta => generateNode(urlWithMeta))

  // Add meta data to edges
  const finalEdges = finalEdgesWithoutMeta.map(generateEdge)

  const customShapes = {
    banner: {
      renderer: BannerNodeLabel,
      intersection: (node: NodeOptions, point: Point, valueCache: ValueCache) => {
        const labelSize = valueCache.value(`${node.id}-label-size`)
        const path = calculateBannerPath(labelSize, true)
        return intersectPath(node, point, path)
      }
    }
  }

  const customMarkerComponents = {
    custom: CustomMarker
  }

  const [stage, setStage] = useState(0)
  const [stageEvent, setStageEvent] = useState()
  const [highlightedNode, setHighlightedNode] = useState()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgHeight, setSvgHeight] = useState(0)

  // HACK: dagre-reactjs doesn't re-render the graph right away when
  // the state changes, but waits until stage is incremented.
  // Increment stage when searchParams change and change stageEvent to
  // ensure that the viewer is centered.

  useEffect(() => {
    setStage(prevStage => prevStage + 1)
    setStageEvent('paramsChange')
  }, [toAuthorURLs, showSources, showBlockers, showBlocked, showAllBlocked, showShortestPath, showFunneled])

  const [showOptions, setShowOptions] = useState(false)

  const renderNode = (
    node: NodeOptions,
    reportSize: ReportSize,
    valueCache: ValueCache,
    layoutStage: number
  ): ReactElement => {
    return (
      <Node
        key={node.id}
        node={node}
        reportSize={reportSize}
        valueCache={valueCache}
        html={false}
      >
        {{
          shape: (innerSize: Size) => {
            if (node.id === highlightedNode) {
              node.styles.shape.styles = {
                ...node.styles.shape.styles,
                stroke: 'var(--inverted-color-bg)'
              }
            }
            return (
              <g
                onMouseEnter={() => {
                  setStage(stage + 1)
                  setStageEvent('mouseEvent')
                  setHighlightedNode(node.id)
                }}
                onMouseLeave={() => {
                  setStage(stage + 1)
                  setStageEvent('mouseEvent')
                  setHighlightedNode(undefined)
                }}
              >
                <BannerNodeLabel node={node} innerSize={innerSize} />
              </g>
            )
          },
          label: () => <NodeTextLabel node={node} />
        }}
      </Node>
    )
  }

  // For UncontrolledReactSVGPanZoom
  const Viewer = useRef(null)

  return (
    <div id='peer-graph'>
      <AutoSizer>
        {({ height, width }) => (
          <UncontrolledReactSVGPanZoom
            width={width}
            height={height}
            tool='auto'
            background='var(--color-bg)'
            SVGBackground='var(--color-bg)'
            detectAutoPan={false}
            miniatureProps={{ position: 'none' }}
            customToolbar={() => PeerGraphToolbar({ author, showOptions, setShowOptions, ...searchParamsOpts })}
            ref={Viewer}
          >
            <svg width={svgWidth} height={svgHeight}>
              <DagreReact
                nodes={finalNodes}
                edges={finalEdges}
                multigraph
                customShapes={customShapes}
                customMarkerComponents={customMarkerComponents}
                renderNode={renderNode}
                stage={stage}
                graphLayoutComplete={(width, height) => {
                  if (stageEvent !== 'mouseEvent') {
                    setSvgWidth(width)
                    setSvgHeight(height)
                    setTimeout(() => {
                      Viewer.current?.fitToViewer('center', 'center')
                    }, 0)
                  }
                }}
                graphOptions={{
                  marginx: 10,
                  marginy: 10,
                  ranksep: 20,
                  nodesep: 35
                }}
                renderingOrder={['edges', 'edgeLabels', 'nodes']}
              />
            </svg>
          </UncontrolledReactSVGPanZoom>
        )}
      </AutoSizer>
    </div>
  )
}
