/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
const BOOLEAN_QUERY_TRUE = 't'

export function setBooleanSearchParams (searchParams: URLSearchParams, key: string, newValue: boolean): void {
  if (newValue) {
    searchParams.set(key, BOOLEAN_QUERY_TRUE)
  } else {
    searchParams.delete(key)
  }
}

export function deleteSearchParamByKeyValue (searchParams: URLSearchParams, key: string, value: string): URLSearchParams {
  const allKeys = []
  const entriesToKeep = []

  for (const [k, v] of searchParams.entries()) {
    if (k === undefined || v === undefined) continue
    allKeys.push(k)
    if (k === key && v === value) continue
    entriesToKeep.push([k, v])
  }

  for (const k of allKeys) {
    searchParams.delete(k)
  }

  for (const [k, v] of entriesToKeep) {
    searchParams.append(k, v)
  }
}
