/*
  Copyright (C) 2021 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React from 'react'
import { Link } from 'react-router-dom'

import './banner.scss'

import { bannerBackgroundGenerator } from '../utils/stylesGenerators'
import { removeHyperPrefix } from '../dataModels/pointUtils'

import { useAppSelector } from '../hooks/useRedux'

interface Props {
  authorURL: string
  handleMouseOver?: () => void
  handleMouseOut?: () => void
  isSelected?: boolean
}

const DEFAULT_COLOR = 'gray'

export const Banner = React.forwardRef<HTMLButtonElement, Props>(({ authorURL, isSelected, handleMouseOver, handleMouseOut }, ref) => {
  const author = useAppSelector(({ authors }) => authors.byURL[authorURL])
  const { darkMode } = useAppSelector(({ settings }) => settings)

  const { name, color } = author ?? { name: '', color: DEFAULT_COLOR }

  const { backgroundImageAuthorColor, backgroundImageColorFG } = bannerBackgroundGenerator(color, darkMode, isSelected)

  return (
    <button
      className='banner'
      style={{
        // @ts-expect-error
        '--backgroundImageAuthorColor': backgroundImageAuthorColor,
        '--backgroundImageColorFG': backgroundImageColorFG
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={ref}
    >
      <Link to={`/${removeHyperPrefix(authorURL)}`}>
        {name}
      </Link>
    </button>
  )
})
