/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import { Modals } from '../slices/modal'
import { useAppSelector } from '../hooks/useRedux'

import SettingsModal from './SettingsModal'
import { SelectIdentityModal } from './SelectIdentityModal'
import { CreateIdentityModal } from './CreateIdentityModal'
import { EditIdentityModal } from './EditIdentityModal'
import { DraftsModal } from './DraftsModal'
import { MovePointsDraftsModal } from './MovePointsDraftsModal'
import { ReplyToPointDraftsModal } from './ReplyToPointDraftsModal'

export function ModalSwitcher (): ReactElement | null {
  const { modal } = useAppSelector(({ modal }) => modal)

  switch (modal) {
    case Modals.settingsModal:
      return <SettingsModal />
    case Modals.selectIdentityModal:
      return <SelectIdentityModal />
    case Modals.createIdentityModal:
      return <CreateIdentityModal />
    case Modals.editIdentityModal:
      return <EditIdentityModal />
    case Modals.draftsModal:
      return <DraftsModal />
    case Modals.movePointsDraftsModal:
      return <MovePointsDraftsModal />
    case Modals.replyToPointDraftsModal:
      return <ReplyToPointDraftsModal />
  }
  return null
}
