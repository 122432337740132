/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import './page-container.scss'

import { SearchPanel } from './SearchPanel'
import { useAppSelector } from '../hooks/useRedux'

interface Props {
  topBarContents: React.ReactNode
  children?: React.ReactNode
}

export const PageContainer = ({
  topBarContents,
  children
}: Props): ReactElement => {
  const searchPanelIsOpen = useAppSelector(({ panels }) => panels.searchPanelIsOpen)

  return (
    <div className='page-container'>
      <div className='page-container-main-panel'>
        {topBarContents}
        {children}
      </div>
      {searchPanelIsOpen && <SearchPanel />}
    </div>
  )
}
