/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
 */
import React, { ReactElement } from 'react'
import { Author, helpers } from 'ushin-db'
import { useSearchParams } from 'react-router-dom'
import { ListTask, X } from 'react-bootstrap-icons'

import './peer-graph.scss'

import { setBooleanSearchParams } from '../utils/searchParamsHelpers'

import { SearchParamsOpts } from './PeerGraph'
import { TrustParameters } from './TrustParameters'
import { PeerGraphToPeerItem } from './PeerGraphToPeerItem'

interface PeerGraphToolbarProps extends SearchParamsOpts {
  author: Author
  showOptions: boolean
  setShowOptions: React.Dispatch<React.SetStateAction<boolean>>
}

export const PeerGraphToolbar = ({
  author,
  showOptions,
  setShowOptions,
  toAuthorURLs,
  showSources,
  showBlockers,
  showBlocked,
  showAllBlocked,
  showShortestPath,
  showFunneled
}: PeerGraphToolbarProps): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleShowSourcesCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, helpers.SOURCE, !showSources)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowBlockersCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, helpers.BLOCKER, !showBlockers)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowBlockedCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, helpers.BLOCKED, !showBlocked)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowAllBlockedCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, 'allblocked', !showAllBlocked)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowShortestPathCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, 'shortestpath', !showShortestPath)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowFunneledCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, 'funnel', !showFunneled)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleSummaryBoxClick (e: React.MouseEvent<HTMLDivElement>): void {
    setShowOptions(showOptions => !showOptions)
  }

  const peerGraphShowSourceLabelClassname = showSources ? 'shown' : ''
  const peerGraphShowBlockerLabelClassname = showBlockers ? 'shown' : ''
  const peerGraphShowBlockedLabelClassname = showBlockers && showBlocked ? 'shown' : ''

  return (
    <div id='peer-graph-toolbar' style={{ borderColor: author.color }}>
      <button className='button' onClick={handleSummaryBoxClick} title='Show options'>
        {showOptions ? <X className='bi' /> : <ListTask className='bi' />}
      </button>
      {showOptions && (
        <div className='peer-graph-toolbar-options'>
          <div className='peer-graph-toolbar-group source'>
            <input
              type='checkbox'
              id='showSources'
              name='showSources'
              onChange={handleShowSourcesCheckBoxChange}
              checked={showSources}
            />
            <label className={peerGraphShowSourceLabelClassname} htmlFor='showSources'>source</label>
            <TrustParameters authorURL={author.url} trustArea={helpers.SOURCE} />
          </div>
          <div className='peer-graph-toolbar-group blocker'>
            <input
              type='checkbox'
              id='showBlockers'
              name='showBlockers'
              onChange={handleShowBlockersCheckBoxChange}
              checked={showBlockers}
            />
            <label className={peerGraphShowBlockerLabelClassname} htmlFor='showBlockers'>blocker</label>
            <TrustParameters authorURL={author.url} trustArea={helpers.BLOCKER} />
          </div>
          <div className='peer-graph-toolbar-group no-params blocked'>
            <input
              type='checkbox'
              id='showBlocked'
              name='showBlocked'
              disabled={!showBlockers}
              onChange={handleShowBlockedCheckBoxChange}
              checked={showBlocked}
            />
            <label className={peerGraphShowBlockedLabelClassname} htmlFor='showBlocked'>blocked</label>
            <input
              type='checkbox'
              id='showAllBlocked'
              name='showAllBlocked'
              disabled={!showBlockers || !showBlocked}
              onChange={handleShowAllBlockedCheckBoxChange}
              checked={showAllBlocked}
            />
            <label htmlFor='showAllBlocked'>all blocked</label>
          </div>
          <div className='peer-graph-toolbar-group no-params'>
            <input
              type='checkbox'
              id='showShortestPath'
              name='showShortestPath'
              onChange={handleShowShortestPathCheckBoxChange}
              checked={showShortestPath}
            />
            <label htmlFor='showShortestPath'>shortest path</label>
          </div>
          <div>
            <input
              type='checkbox'
              id='showFunneled'
              name='showFunneled'
              onChange={handleShowFunneledCheckBoxChange}
              checked={showFunneled}
            />
            <label htmlFor='showFunneled'>funnel to:</label>
            <div className='peer-graph-to-peers'>
              {toAuthorURLs.map(authorURL => <PeerGraphToPeerItem authorURL={authorURL} key={authorURL} />)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
