/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { Author, AuthorStore } from 'ushin-db'
import { loadMessagesSuccess } from './published'
import { addSearchResultMessage } from './search'
import { initAppSuccess } from './initApp'

export interface AuthorsState {
  byURL: AuthorStore
  writableIdentities: {
    [url: string]: string
  }
  currentIdentity?: string
}

const initialAuthorsState: AuthorsState = {
  byURL: {},
  writableIdentities: {}
}

const authorsSlice = createSlice({
  name: 'authors',
  initialState: initialAuthorsState,
  reducers: {
    getAuthorInfo: (state, action: PayloadAction<{
      authorURLs: string[]
    }>) => state,

    getAuthorInfoSuccess: (state, action: PayloadAction<{
      authorStore: AuthorStore
    }>) => {
      state.byURL = addAuthors(state.byURL, action.payload.authorStore)
    },

    setAuthorInfo: (state, action: PayloadAction<{
      url: string
      name: string
      color: string
    }>) => state,

    setAuthorInfoSuccess: (state, action: PayloadAction<{
      author: Author
    }>) => {
      const { url } = action.payload.author
      state.byURL[url] = action.payload.author
    },

    addIdentity: (state, action: PayloadAction<{
      name: string
      color: string
    }>) => state,

    addIdentitySuccess: (state, action: PayloadAction<{
      author: Author
      shortFormURL: string
    }>) => {
      const { author, shortFormURL } = action.payload
      const { url } = author
      state.byURL[url] = author
      state.currentIdentity = url
      state.writableIdentities[url] = shortFormURL
    },

    selectIdentity: (state, action: PayloadAction<{
      authorURL: string
    }>) => {
      state.currentIdentity = action.payload.authorURL
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(initAppSuccess, loadMessagesSuccess, addSearchResultMessage), (state, action) => {
        state.byURL = addAuthors(state.byURL, action.payload.authorStore)
      })
  }
})

function addAuthors (store: AuthorStore, newAuthors: AuthorStore): AuthorStore {
  return { ...store, ...newAuthors }
}

export const {
  getAuthorInfo,
  getAuthorInfoSuccess,
  setAuthorInfo,
  setAuthorInfoSuccess,
  addIdentity,
  addIdentitySuccess,
  selectIdentity
} = authorsSlice.actions
export default authorsSlice.reducer
