/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import './time-stamp.scss'

interface Props {
  createdAt?: Date
}

export const TimeStamp = ({ createdAt }: Props): ReactElement | null => {
  if (createdAt === undefined) return null

  const year = createdAt.getFullYear().toString()
  const month = padWithZero(createdAt.getMonth() + 1)
  const date = padWithZero(createdAt.getDate())
  const hours = padWithZero(createdAt.getHours())
  const minutes = padWithZero(createdAt.getMinutes())
  const seconds = padWithZero(createdAt.getSeconds())

  const timeStampString = year + '-' + month + '-' + date + '\n' + hours + ':' + minutes + ':' + seconds

  return <div className='time-stamp'>{timeStampString}</div>
}

function padWithZero (date: number): string {
  return ('0' + date.toString()).slice(-2)
}
