/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Funnel } from 'react-bootstrap-icons'
import { helpers, TrustType } from 'ushin-db'

import './peer-list.scss'
import './button.scss'

import { useAppSelector } from '../hooks/useRedux'

import { removeHyperPrefix } from '../dataModels/pointUtils'
import { setBooleanSearchParams } from '../utils/searchParamsHelpers'

import { TrustRank } from './TrustRank'
import { Banner } from './Banner'

interface Props {
  fromAuthorURL: string
  toAuthorURL: string
  trustType: TrustType
}

export const PeerListItem = ({ fromAuthorURL, toAuthorURL, trustType }: Props): ReactElement => {
  const [searchParams] = useSearchParams()

  const isCurrentIdentity = useAppSelector(({ authors }) => authors.currentIdentity === fromAuthorURL)

  const toAuthorColor = useAppSelector(({ authors }) => authors.byURL[toAuthorURL].color)

  const disableTrustSelect = !isCurrentIdentity || fromAuthorURL === toAuthorURL

  return (
    // @ts-expect-error
    <div className='peer-list-item' style={{ '--hover-border-color': toAuthorColor }}>
      <div className='button-group'>
        <Link to={{
          pathname: `/${removeHyperPrefix(fromAuthorURL)}/peer-graph`,
          search: (() => {
            searchParams.set('topeer', removeHyperPrefix(toAuthorURL))
            setBooleanSearchParams(searchParams, 'funnel', true)
            setBooleanSearchParams(searchParams, 'shortestpath', true)
            setBooleanSearchParams(searchParams, helpers.SOURCE, true)
            setBooleanSearchParams(searchParams, helpers.BLOCKER, true)
            setBooleanSearchParams(searchParams, helpers.BLOCKED, true)
            return searchParams.toString()
          })()
        }}
        >
          <button className='button' title='Peer graph'>
            <Funnel className='bi' />
          </button>
        </Link>
        <Banner authorURL={toAuthorURL} />
      </div>
      <div className='button-group'>
        <TrustRank fromAuthorURL={fromAuthorURL} toAuthorURL={toAuthorURL} trustArea={helpers.BLOCKER} disabled={disableTrustSelect} />
        <TrustRank fromAuthorURL={fromAuthorURL} toAuthorURL={toAuthorURL} trustArea={helpers.SOURCE} disabled={disableTrustSelect} />
      </div>
    </div>
  )
}
