/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, ReactNode } from 'react'
import { helpers } from 'ushin-db'

import './button.scss'

import { useAppSelector } from '../hooks/useRedux'

import { TopBarContainer } from './TopBarContainer'
import { TrustRank } from './TrustRank'

interface Props {
  authorURL: string
}

export const ProfilePageTopBar = ({ authorURL }: Props): ReactElement => {
  const currentIdentity = useAppSelector(({ authors }) => authors.currentIdentity)

  let leftButtons: ReactNode = false
  const rightButtons: ReactNode = false

  if (currentIdentity !== undefined && currentIdentity !== authorURL) {
    leftButtons = (
      <>
        <TrustRank fromAuthorURL={currentIdentity} toAuthorURL={authorURL} trustArea={helpers.BLOCKER} disabled={false} />
        <TrustRank fromAuthorURL={currentIdentity} toAuthorURL={authorURL} trustArea={helpers.SOURCE} disabled={false} />
      </>
    )
  }

  return <TopBarContainer leftButtons={leftButtons} rightButtons={rightButtons} />
}
