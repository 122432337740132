/*
  Copyright (C) 2021-22 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RegionI } from '../dataModels/dataModels'

interface ExpandedRegionState {
  region: RegionI | null
}

const initialExpandedRegionState: ExpandedRegionState = {
  region: null
}

const expandedRegionSlice = createSlice({
  name: 'expandedRegion',
  initialState: initialExpandedRegionState,
  reducers: {
    toggleExpandedRegion: (state, action: PayloadAction<{
      region: RegionI
    }>) => {
      const { region } = action.payload
      return (state.region === region)
        ? { region: null }
        : { region }
    },

    setExpandedRegion: (state, action: PayloadAction<{
      region: RegionI
    }>) => ({ region: action.payload.region })
  }
})

export const {
  toggleExpandedRegion,
  setExpandedRegion
} = expandedRegionSlice.actions
export default expandedRegionSlice.reducer
