/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { DraftMessage, DraftPoint, DraftQuotePoint, Reference } from 'ushin-db'
import undoable from 'redux-undo'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { customFilter } from '../utils/customUndoableConfig'
import { allPointShapes, DraftQuotePointWithShape } from '../dataModels/dataModels'
import { isQuote } from '../dataModels/pointUtils'
import {
  draftMessageCreate,
  setMain,
  replyToPoint,
  draftPointCreate,
  draftPointUpdate,
  pointsMoveWithinMessage,
  deletePoints,
  autoDeleteEmptyPoint,
  combinePoints,
  splitIntoTwoPoints
} from './drafts'

export interface DraftState {
  message: DraftMessage
  points: {
    [url: string]: DraftPoint | DraftQuotePoint
  }
}

const initialDraftState = {} as DraftState // eslint-disable-line @typescript-eslint/consistent-type-assertions

const draftSlice = createSlice({
  name: 'draft',
  initialState: initialDraftState,
  reducers: {
    addPoints: (state, action: PayloadAction<{
      points: Array<DraftPoint | DraftQuotePointWithShape>
    }>) => {
      for (const point of action.payload.points) {
        state.message.shapes[point.shape].push(point.url)
        // TODO: consider refactoring action.payload.points to be an object, not array
        if (isQuote(point)) {
          // @ts-expect-error
          delete point.shape
        }
        state.points[point.url] = point
      }
    },

    undoDraft: (state, action: PayloadAction<{
      messageURL: string
    }>) => state,

    redoDraft: (state, action: PayloadAction<{
      messageURL: string
    }>) => state
  },
  extraReducers: (builder) => {
    builder
      .addCase(draftMessageCreate, (state, action) => {
        const { url, _id } = action.payload

        state.message = {
          url,
          _id,
          shapes: {
            facts: [],
            thoughts: [],
            feelings: [],
            needs: [],
            topics: [],
            actions: [],
            people: []
          },
          replyHistory: []
        }
        state.points = {}
      })

      .addCase(draftPointCreate, (state, action) => {
        const { shape, index, main, pointURL, pointId } = action.payload

        if (main) {
          state.message.main = pointURL
        } else {
          state.message.shapes[shape].splice(index, 0, pointURL)
        }

        state.points[pointURL] = {
          _id: pointId,
          url: pointURL,
          shape,
          content: ''
        }
      })

      .addCase(pointsMoveWithinMessage, (state, action) => {
        const { pointsToMoveURLs, region, index } = action.payload
        const { message, points } = state
        const pointURLs: string[] = message.shapes[region]
        let newPointURLs: string[] = []

        // Rebuild array of pointURLs for state.shapes[region]
        pointURLs.forEach((pointURL: string, i: number) => {
          if (i === index) {
            newPointURLs = newPointURLs.concat(pointsToMoveURLs)
          }

          if (!pointsToMoveURLs.includes(pointURL)) {
            newPointURLs.push(pointURL)
          }
        })

        if (index === pointURLs.length) {
          newPointURLs = newPointURLs.concat(pointsToMoveURLs)
        }

        // Delete points from original locations
        const { shapes, main } = message
        for (const shape of allPointShapes) {
          shapes[shape] = shapes[shape].filter(
            (url: string) => !pointsToMoveURLs.includes(url)
          )
        }

        main !== undefined && pointsToMoveURLs.includes(main) && delete message.main

        // Set the pointURLs for the destination region
        message.shapes[region] = newPointURLs

        // Set new shape attribute for moved points
        for (const url of pointsToMoveURLs) {
          if (!isQuote(points[url])) (points[url] as DraftPoint).shape = region
        }
      })

      .addCase(setMain, (state, action) => {
        const { newMainURL, newMainShape, oldMainURL, oldMainShape } = action.payload
        const { message } = state

        // Move current main point to its shapeRegion
        if (oldMainURL !== undefined && oldMainShape !== undefined) {
          message.shapes[oldMainShape].push(oldMainURL)
        }

        // Delete point from original location
        message.shapes[newMainShape] = message.shapes[newMainShape].filter(
          (url: string) => url !== newMainURL
        )

        message.main = newMainURL
      })

      .addCase(combinePoints, (state, action) => {
        const { shape, keepIndex, deleteIndex, pointToKeep, pointToDelete } = action.payload
        const { message, points } = state

        if (keepIndex < deleteIndex) {
          (points[pointToKeep.url] as DraftPoint).content = pointToKeep.content + pointToDelete.content
        } else {
          (points[pointToKeep.url] as DraftPoint).content = pointToDelete.content + pointToKeep.content
        }

        message.shapes[shape] = message.shapes[shape]
          .filter((url: string) => url !== pointToDelete.url)
        delete points[pointToDelete.url] // eslint-disable-line @typescript-eslint/no-dynamic-delete
      })

      .addCase(splitIntoTwoPoints, (state, action) => {
        const { sliceIndex, pointURL, newPointId, newPointURL } = action.payload
        const { message, points } = state

        const point = points[pointURL]
        if (point === undefined || isQuote(point)) return

        const { shape } = point

        const newPointIndex =
          message.shapes[shape].findIndex((url: string) => url === pointURL) as number + 1
        message.shapes[shape].splice(newPointIndex, 0, newPointURL)

        const content = point.content.slice(0, sliceIndex)
        const newContent = point.content.slice(sliceIndex)

        point.content = content

        points[newPointURL] = {
          content: newContent,
          _id: newPointId,
          url: newPointURL,
          shape
        }
      })

      .addCase(replyToPoint, (state, action) => {
        const { originalPointURL, originalMessage } = action.payload

        const newReply: Reference = { messageURL: originalMessage.url, mainPointURL: originalPointURL }

        if (originalMessage.main !== originalPointURL) {
          newReply.mainPointURL = originalMessage.main
          newReply.subPointURL = originalPointURL
        }

        const replyHistory = originalMessage.replyHistory.concat([newReply])

        state.message.replyHistory = replyHistory
      })

      .addCase(draftPointUpdate, (state, action) => {
        state.points[action.payload.point.url] = action.payload.point
      })

      .addMatcher(isAnyOf(deletePoints, autoDeleteEmptyPoint), (state, action) => {
        const { pointURLs } = action.payload
        const { shapes, main } = state.message

        for (const shape of allPointShapes) {
          state.message.shapes[shape] = shapes[shape].filter(
            (url: string) => !pointURLs.includes(url)
          )
        }

        main !== undefined && pointURLs.includes(main) && delete state.message.main

        for (const url of pointURLs) {
          delete state.points[url] // eslint-disable-line @typescript-eslint/no-dynamic-delete
        }
      })
  }
})

export const {
  addPoints,
  undoDraft,
  redoDraft
} = draftSlice.actions
export default undoable(draftSlice.reducer, {
  undoType: undoDraft.toString(),
  redoType: redoDraft.toString(),
  ignoreInitialState: true,
  filter: customFilter
})
