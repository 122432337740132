/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { DraftPoint, Message, PointShape, URLs } from 'ushin-db'
import { Navigate } from 'react-router'
import { StateWithHistory } from 'redux-undo'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'

import undoableDraftReducer, {
  DraftState,
  addPoints,
  undoDraft,
  redoDraft
} from './draft'
import { AppThunk } from './index'
import { deepEquals } from '../utils'
import { DraftQuotePointWithShape, isPointShape } from '../dataModels/dataModels'
import {
  getPointByURL,
  getShape,
  isQuote,
  isDraft
} from '../dataModels/pointUtils'
import { publishMessageSuccess } from '../slices/published'
import { initAppSuccess } from './initApp'

export interface DraftsState {
  byURL: {
    [url: string]: StateWithHistory<DraftState>
  }
  allURLs: string[]
}

export const initialDraftsState: DraftsState = {
  byURL: {},
  allURLs: []
}

const draftsSlice = createSlice({
  name: 'drafts',
  initialState: initialDraftsState,
  reducers: {
    setDrafts: (state, action: PayloadAction<{
      draftsState: DraftsState
    }>) => state,

    draftMessageCreate: {
      reducer: (state, action: PayloadAction<{
        url: string
        _id: any
        navigate: Navigate
      }>) => {
        const { url, _id } = action.payload
        draftMessageCreateHelper(state, url, _id)
      },
      prepare: (unpreparedPayload: {
        navigate: Navigate
      }) => {
        const { navigate } = unpreparedPayload
        const _id = URLs.makeID()
        const url = URLs.makeDraftURL('messages', _id)

        return {
          payload: {
            _id,
            url,
            navigate
          }
        }
      }
    },

    draftMessageDelete: {
      reducer: (state, action: PayloadAction<{
        messageURL: string
        newMessageId: any
        newMessageURL: string
        navigate: Navigate
      }>) => {
        const { messageURL, newMessageId, newMessageURL } = action.payload
        delete state.byURL[messageURL] // eslint-disable-line @typescript-eslint/no-dynamic-delete
        state.allURLs = state.allURLs.filter((url) => url !== messageURL)

        if (state.allURLs.length === 0) {
          draftMessageCreateHelper(state, newMessageURL, newMessageId)
        }
      },
      prepare: (unpreparedPayload: {
        messageURL: string
        navigate: Navigate
      }) => {
        const { messageURL, navigate } = unpreparedPayload
        const newMessageId = URLs.makeID()
        const newMessageURL = URLs.makeDraftURL('messages', newMessageId)

        return {
          payload: {
            messageURL,
            // Pass _id & url in case we need to make a new draft message
            newMessageId,
            newMessageURL,
            navigate
          }
        }
      }
    },

    setMain: (state, action: PayloadAction<{
      newMainURL?: string
      messageURL: string
      newMainShape: PointShape
      oldMainURL?: string
      oldMainShape?: PointShape
    }>) => {
      return passToDraftReducer(state, action)
    },

    replyToPoint: (state, action: PayloadAction<{
      createNewMessage: boolean
      originalPointURL: string
      originalMessage: Message
      replyMessageURL: string
      replyMessageId: string
      navigate: Navigate
    }>) => {
      const { createNewMessage, replyMessageURL, replyMessageId } = action.payload
      createNewMessage && draftMessageCreateHelper(state, replyMessageURL, replyMessageId)

      state.byURL[replyMessageURL] = undoableDraftReducer(state.byURL[replyMessageURL], action)
    },

    draftPointCreate: {
      reducer: (state, action: PayloadAction<{
        messageURL: string
        shape: PointShape
        index: number
        main: boolean
        pointURL: string
        pointId: any
      }>) => passToDraftReducer(state, action),
      prepare: (unpreparedPayload: {
        messageURL: string
        shape: PointShape
        index: number
        main: boolean
      }) => {
        const { messageURL, shape, index, main } = unpreparedPayload
        const pointId = URLs.makeID()
        const pointURL = URLs.makeDraftURL('points', pointId)

        return {
          payload: {
            messageURL,
            shape,
            index,
            main,
            pointURL,
            pointId
          }
        }
      }
    },

    draftPointUpdate: (state, action: PayloadAction<{
      messageURL: string
      point: DraftPoint
    }>) => {
      const { messageURL, point } = action.payload
      const priorPoint = state.byURL[messageURL].present.points[point.url]
      if (isQuote(priorPoint)) throw new Error('tried to update quote point')
      if (point.content === (priorPoint).content) return

      return passToDraftReducer(state, action)
    },

    pointsMoveWithinMessage: (state, action: PayloadAction<{
      messageURL: string
      pointsToMoveURLs: string[]
      region: PointShape
      index: number
    }>) => passToDraftReducer(state, action),

    movePointsFromMessage: (state, action: PayloadAction<{
      createNewMessage: boolean
      toMessageId: string
      toMessageURL: string
      fromMessageURL: string
      oldPointURLs: string[]
      newPoints: Array<DraftPoint | DraftQuotePointWithShape>
      navigate: Navigate
    }>) => {
      const { createNewMessage, toMessageId, toMessageURL, fromMessageURL, oldPointURLs, newPoints } = action.payload

      createNewMessage && draftMessageCreateHelper(state, toMessageURL, toMessageId)

      state.byURL[toMessageURL] = undoableDraftReducer(
        state.byURL[toMessageURL],
        addPoints({ points: newPoints }))

      state.byURL[fromMessageURL] = undoableDraftReducer(
        state.byURL[fromMessageURL],
        deletePoints({
          messageURL: fromMessageURL,
          pointURLs: oldPointURLs
        })
      )
    },

    quotePointsFromMessage: (state, action: PayloadAction<{
      createNewMessage: boolean
      toMessageId: string
      toMessageURL: string
      newPoints: DraftQuotePointWithShape[]
      navigate: Navigate
    }>) => {
      const { createNewMessage, toMessageId, toMessageURL, newPoints } = action.payload

      createNewMessage && draftMessageCreateHelper(state, toMessageURL, toMessageId)

      const addPointsAction = addPoints({ points: newPoints })
      state.byURL[toMessageURL] = undoableDraftReducer(state.byURL[toMessageURL], addPointsAction)
    },

    deletePoints: (state, action: PayloadAction<{
      messageURL: string
      pointURLs: string[]
    }>) => passToDraftReducer(state, action),

    autoDeleteEmptyPoint: (state, action: PayloadAction<{
      messageURL: string
      pointURLs: string[]
    }>) => passToDraftReducer(state, action),

    combinePoints: (state, action: PayloadAction<{
      messageURL: string
      shape: PointShape
      keepIndex: number
      deleteIndex: number
      pointToKeep: DraftPoint
      pointToDelete: DraftPoint
    }>) => passToDraftReducer(state, action),

    splitIntoTwoPoints: {
      reducer: (state, action: PayloadAction<{
        pointURL: string
        sliceIndex: number
        messageURL: string
        newPointId: any
        newPointURL: string
      }>) => passToDraftReducer(state, action),
      prepare: (unpreparedPayload: {
        pointURL: string
        sliceIndex: number
        messageURL: string
      }) => {
        const { pointURL, sliceIndex, messageURL } = unpreparedPayload
        const newPointId = URLs.makeID()
        const newPointURL = URLs.makeDraftURL('points', newPointId)

        return {
          payload: {
            pointURL,
            sliceIndex,
            messageURL,
            newPointURL,
            newPointId
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initAppSuccess, (state, action) => action.payload.draftsState)
      .addCase(publishMessageSuccess, (state, action) => {
        const { draftMessageURL } = action.payload
        delete state.byURL[draftMessageURL] // eslint-disable-line @typescript-eslint/no-dynamic-delete
        state.allURLs = state.allURLs.filter((url) => url !== draftMessageURL)
      })

      .addCase(undoDraft, (state, action) => passToDraftReducer(state, action))

      .addCase(redoDraft, (state, action) => passToDraftReducer(state, action))
  }
})

interface ReplyToPointThunkPayload {
  originalMessageURL: string
  replyMessageURL?: string
  navigate: Navigate
}

export const replyToPointThunk = (
  payload: ReplyToPointThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { published, selectedPoints } = getState()
    const { originalMessageURL, navigate } = payload

    const originalPointURL = selectedPoints.pointURLs[0] // openReplyToPointDraftsModal should be handled by selectedPoints reducer, leaving a lone selected point
    const originalMessage = published.messages[originalMessageURL]

    const createNewMessage = payload.replyMessageURL === undefined

    const replyMessageId = URLs.makeID()
    const replyMessageURL = payload.replyMessageURL ?? URLs.makeDraftURL('messages', replyMessageId)

    dispatch(replyToPoint({
      createNewMessage,
      originalPointURL,
      originalMessage,
      replyMessageURL,
      replyMessageId,
      navigate
    }))
  }
}

interface SetMainThunkPayload {
  newMainURL?: string
  messageURL: string
}

export const setMainThunk = (
  payload: SetMainThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { published, drafts, drag } = getState()

    const { messageURL } = payload
    let { newMainURL } = payload
    if (newMainURL === undefined) newMainURL = drag.pointURL
    const oldMainURL: string | undefined =
      drafts.byURL[messageURL].present.message.main

    if (newMainURL === oldMainURL || newMainURL === undefined) return

    const newMainShape = getShape(getPointByURL(newMainURL, published, drafts), published)

    // oldMainShape is used to move the old main point back to its proper region
    let oldMainShape: PointShape | undefined
    if (oldMainURL !== undefined) {
      oldMainShape = getShape(getPointByURL(oldMainURL, published, drafts), published)
    }

    dispatch(
      setMain({
        messageURL,
        newMainURL,
        newMainShape,
        oldMainURL,
        oldMainShape
      })
    )
  }
}

interface PointsMoveWithinMessageThunkPayload {
  messageURL: string
}

export const pointsMoveWithinMessageThunk = (
  { messageURL }: PointsMoveWithinMessageThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { published, drafts, drag, selectedPoints } = getState()

    if (drag.context === undefined) return

    const { region, index } = drag.context

    // Only move points when hovering over semantic screen regions
    if (!isPointShape(region)) return

    // Don't move quoted points to regions with a different shape
    const selectedPointURLs = selectedPoints.pointURLs
    const pointsToMoveURLs = selectedPointURLs.filter(
      (url) => {
        const point = getPointByURL(url, published, drafts)
        return !isQuote(point) || getShape(point, published) === region
      }
    )

    dispatch(
      pointsMoveWithinMessage({
        messageURL,
        pointsToMoveURLs,
        region,
        index
      })
    )
  }
}

interface MoveOrQuotePointsFromMessageThunkPayload {
  toMessageURL?: string
  fromMessageURL: string
  navigate: Navigate
}

export const moveOrQuotePointsFromMessageThunk = (
  payload: MoveOrQuotePointsFromMessageThunkPayload
): AppThunk => {
  return async (dispatch, getState) => {
    const { selectedPoints, published, drafts } = getState()
    const { fromMessageURL, navigate } = payload

    if (selectedPoints.pointURLs.length === 0) return

    const createNewMessage = payload.toMessageURL === undefined

    // toMessageId will be used if (!!createNewMessage)
    const toMessageId = URLs.makeID()
    const toMessageURL = payload.toMessageURL ?? URLs.makeDraftURL('messages', toMessageId)

    // Don't move points to the same message
    if (fromMessageURL === toMessageURL) return

    if (isDraft(fromMessageURL)) {
      // When we move points to the new message, the new points must be deleted in the old message and created anew in the new message
      // Making new points instead of referring to the old ones in the new message means that if the user undoes the move action in the old message,
      // the restored points (which were deleted from the old message) will be copies of, not references to, the new points in the new message which remain in the new message after the undo.
      const oldPointURLs: string[] = []
      const newPoints: Array<DraftPoint | DraftQuotePointWithShape> = []

      for (const url of selectedPoints.pointURLs) {
        oldPointURLs.push(url)

        const newPointId = URLs.makeID()
        const newPointURL = URLs.makeDraftURL('points', newPointId)

        const point = {
          ...drafts.byURL[fromMessageURL].present.points[url],
          _id: newPointId,
          url: newPointURL
        }

        if (isQuote(point)) {
          // Pass the shape along with each quote point so
          // draftMessageReducer knows where in the shapes object to put it
          point.shape = getShape(point, published)
        }

        newPoints.push(point as DraftPoint | DraftQuotePointWithShape)
      }

      dispatch(movePointsFromMessage({
        createNewMessage,
        toMessageId,
        toMessageURL,
        fromMessageURL,
        oldPointURLs,
        newPoints,
        navigate
      }))
    } else {
      const newPoints: DraftQuotePointWithShape[] = selectedPoints.pointURLs.map((url) => {
        const pointToQuote = published.points[url]

        const shape = getShape(pointToQuote, published)

        // Don't create quotes of quotes, just copy the original quote attribute
        const quote = isQuote(pointToQuote)
          ? pointToQuote.quote
          : { messageURL: fromMessageURL, pointURL: pointToQuote.url }

        const quotePointId = URLs.makeID()
        const quotePointURL = URLs.makeDraftURL('points', quotePointId)

        return {
          _id: quotePointId,
          url: quotePointURL,
          quote,
          shape
        }
      })

      dispatch(quotePointsFromMessage({
        createNewMessage,
        toMessageId,
        toMessageURL,
        newPoints,
        navigate
      }))
    }
  }
}

interface DeletePointsThunkPayload {
  messageURL: string
  pointURLs: string[]
}

export const deletePointsThunk = (
  payload: DeletePointsThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { selectedPoints } = getState()
    const pointURLs = Array.from(new Set([...payload.pointURLs, ...selectedPoints.pointURLs]))
    dispatch(deletePoints({ ...payload, pointURLs }))
  }
}

interface CombinePointsThunkPayload {
  messageURL: string
  shape: PointShape
  keepIndex: number
  deleteIndex: number
}

export const combinePointsThunk = (
  payload: CombinePointsThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { drafts } = getState()
    const { shape, messageURL, keepIndex, deleteIndex } = payload
    const { message, points } = drafts.byURL[messageURL].present

    const pointToKeepURL = message.shapes[shape][keepIndex]
    const pointToDeleteURL = message.shapes[shape][deleteIndex]

    if (pointToKeepURL === undefined || pointToDeleteURL === undefined) return

    const pointToKeep = points[pointToKeepURL]
    const pointToDelete = points[pointToDeleteURL]

    if (isQuote(pointToKeep) || isQuote(pointToDelete)) return

    dispatch(
      combinePoints({
        messageURL,
        shape,
        keepIndex,
        deleteIndex,
        pointToKeep,
        pointToDelete
      })
    )
  }
}

interface UndoDraftThunkPayload {
  messageURL: string
}

export function undoDraftThunk (payload: UndoDraftThunkPayload): AppThunk {
  return (dispatch, getState) => {
    const { messageURL } = payload
    const { present, past } = getState().drafts.byURL[messageURL]
    const [lastPast] = past.slice(-1)
    dispatch(undoDraft({ messageURL }))
    if (deepEquals(present, lastPast)) {
      dispatch(undoDraftThunk({ messageURL }))
    }
  }
}

interface RedoDraftThunkPayload {
  messageURL: string
}

export function redoDraftThunk (payload: RedoDraftThunkPayload): AppThunk {
  return (dispatch, getState) => {
    const { messageURL } = payload
    const { present, future } = getState().drafts.byURL[messageURL]
    const nextFuture = future[0]
    dispatch(redoDraft({ messageURL }))
    if (deepEquals(present, nextFuture)) {
      dispatch(redoDraftThunk({ messageURL }))
    }
  }
}

function passToDraftReducer (
  state: DraftsState,
  // TODO: better typing for PayloadAction?
  // Previously, I'd listed out all of the possible action types which would be passed to passToDraftReducer, but they're defined inline now in createSlice
  action: PayloadAction<any>
): DraftsState {
  return produce(state, (draft) => {
    const { messageURL } = action.payload
    draft.byURL[messageURL] = undoableDraftReducer(draft.byURL[messageURL], action)
  })
}

// TODO: check that this function can return void, instead of DraftsState
function draftMessageCreateHelper (state: DraftsState, url: string, _id: any): void {
  // TODO: use an action creator here
  const draftMessageCreateAction = {
    type: draftMessageCreate.toString(),
    payload: { url, _id }
  }
  state.byURL[url] = undoableDraftReducer(state.byURL[url], draftMessageCreateAction)
  if (Object.keys(state.byURL[url].present).length === 0) {
    // Account for redux-undo initialization
    state.byURL[url] = undoableDraftReducer(state.byURL[url], draftMessageCreateAction)
  }
  state.allURLs.unshift(url)
}

export const {
  setDrafts,
  draftMessageCreate,
  draftMessageDelete,
  setMain,
  replyToPoint,
  draftPointCreate,
  draftPointUpdate,
  pointsMoveWithinMessage,
  movePointsFromMessage,
  quotePointsFromMessage,
  deletePoints,
  autoDeleteEmptyPoint,
  combinePoints,
  splitIntoTwoPoints
} = draftsSlice.actions
export default draftsSlice.reducer
