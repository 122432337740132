/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { setAuthorInfo } from '../slices/authors'
import { openSelectOrCreateIdentityModal, closeModal } from '../slices/modal'

import { Banner } from './Banner'
import { ModalContainer } from './ModalContainer'
import { EditIdentityForm } from './EditIdentityForm'

export const EditIdentityModal = (): ReactElement | null => {
  const dispatch = useAppDispatch()

  const currentIdentity = useAppSelector(({ authors }) => authors.currentIdentity)

  const author = useAppSelector(({ authors }) => currentIdentity !== undefined && authors.byURL[currentIdentity])

  if (currentIdentity === undefined || author === false) {
    dispatch(openSelectOrCreateIdentityModal())
    return null
  }

  const handleEditIdentityFormSubmit = (name: string, color: string): void => {
    dispatch(setAuthorInfo({ url: currentIdentity, name, color }))
    dispatch(closeModal())
  }

  return (
    <ModalContainer
      title='Edit identity'
      width='300px'
      closeModal={() => dispatch(closeModal())}
    >
      <Banner authorURL={currentIdentity} />
      <EditIdentityForm _name={author.name} _color={author.color} editIdentity={handleEditIdentityFormSubmit} />
    </ModalContainer>
  )
}
