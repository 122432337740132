/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import Level from 'level'
import { serialize, deserialize } from 'bson'
import { initialDraftsState, DraftsState } from './slices/drafts'
import { containsPoints } from './dataModels/pointUtils'

export class DraftsDB {
  readonly persistedDrafts

  constructor (opts = {
    keyEncoding: 'utf8',
    valueEncoding: 'binary'
  }) {
    this.persistedDrafts = Level('u4u-drafts', opts)
  }

  async setDrafts (draftsState: DraftsState): Promise<void> {
    const serializedDraftsState = serialize(draftsState)
    await this.persistedDrafts.put('draftsState', serializedDraftsState)
  }

  async getDrafts (): Promise<DraftsState> {
    try {
      const serializedDraftsState = await this.persistedDrafts.get('draftsState')
      if (serializedDraftsState === null) return initialDraftsState

      const draftsState = deserialize(serializedDraftsState) as DraftsState

      // Remove empty drafts
      for (const url in draftsState.byURL) {
        const { message } = draftsState.byURL[url].present
        if (!containsPoints(message)) {
          delete draftsState.byURL[url] // eslint-disable-line @typescript-eslint/no-dynamic-delete
          draftsState.allURLs = draftsState.allURLs.filter(_url => _url !== url)
        }
      }

      return draftsState
    } catch (e: any) {
      if (e.name === 'NotFoundError') {
        return initialDraftsState
      } else throw e
    }
  }
}
