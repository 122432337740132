/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { MessageStore, AuthorStore, PointStore } from 'ushin-db'
import { Navigate } from 'react-router'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { initAppSuccess } from './initApp'
import { addSearchResultMessage } from './search'

export interface PublishedState {
  messages: MessageStore
  points: PointStore
}

const initialPublishedState: PublishedState = {
  messages: {},
  points: {}
}

const publishedSlice = createSlice({
  name: 'published',
  initialState: initialPublishedState,
  reducers: {
    publishMessage: (state, action: PayloadAction<{
      draftMessageURL: string
      navigate: Navigate
    }>) => state,

    publishMessageSuccess: (state, action: PayloadAction<{
      draftMessageURL: string
      messageStore: MessageStore
      pointStore: PointStore
      navigate: Navigate
    }>) => {
      const { messageStore, pointStore } = action.payload
      state.messages = addMessages(state.messages, messageStore)
      state.points = addPoints(state.points, pointStore)
    },

    loadMessages: (state, action: PayloadAction<{
      messageURLs: string[]
    }>) => state,

    loadMessagesSuccess: (state, action: PayloadAction<{
      messageStore: MessageStore
      authorStore: AuthorStore
      pointStore: PointStore
    }>) => {
      const { messageStore, pointStore } = action.payload
      state.messages = addMessages(state.messages, messageStore)
      state.points = addPoints(state.points, pointStore)
    }
  },
  extraReducers: builder => builder
    .addMatcher(isAnyOf(initAppSuccess, addSearchResultMessage), (state, action) => {
      state.points = addPoints(state.points, action.payload.pointStore)
    })
    .addMatcher(isAnyOf(addSearchResultMessage), (state, action) => {
      state.messages = addMessages(state.messages, action.payload.messageStore)
    })
})

function addMessages (store: MessageStore, newMessages: MessageStore): MessageStore {
  return { ...store, ...newMessages }
}

function addPoints (store: PointStore, newPoints: PointStore): PointStore {
  return { ...store, ...newPoints }
}

export const {
  publishMessage,
  publishMessageSuccess,
  loadMessages,
  loadMessagesSuccess
} = publishedSlice.actions
export default publishedSlice.reducer
