/*
  Copyright (C) 2021 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
const MIN_CONTENT_SPACE_PIXELS = 50

// TODO: Fix types of ref props
export const useTextareaIndent = (parentRef: any, buttonGroupRef: any):
{
  textareaIndent: string
  textareaNewline: boolean
} => {
  // TODO: Add a callback inside useTextarea to improve performance?
  let textareaIndent = ''
  // TODO: Is it possible to use built-in CSS to create a newline?
  let textareaNewline = false

  if (buttonGroupRef.current !== null) {
    // Indent the first line of the textarea by the width of the buttonGroup
    // TODO: remove type assertion after correcting type of buttonGroupRef
    const buttonGroupWidth = buttonGroupRef.current.getBoundingClientRect().width as number
    textareaIndent = `${buttonGroupWidth}px`

    // If there isn't much space for the point's content to the right
    // of the buttonGroup, display the point content on the next line
    const parentWidth = parentRef.current.getBoundingClientRect().width
    if (parentWidth - buttonGroupWidth < MIN_CONTENT_SPACE_PIXELS) {
      textareaIndent = '0'
      textareaNewline = true
    }
  }
  return { textareaIndent, textareaNewline }
}
