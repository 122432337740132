/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import randomColor from 'randomcolor'

import { useAppDispatch } from '../hooks/useRedux'
import { closeModal } from '../slices/modal'
import { addIdentity } from '../slices/authors'

import { ModalContainer } from './ModalContainer'
import { EditIdentityForm } from './EditIdentityForm'

export const CreateIdentityModal = (): ReactElement => {
  const dispatch = useAppDispatch()

  const handleEditIdentityFormSubmit = (name: string, color: string): void => {
    dispatch(addIdentity({ name, color }))
    dispatch(closeModal())
  }

  return (
    <ModalContainer
      title='Create an identity'
      width='300px'
      closeModal={() => dispatch(closeModal())}
    >
      <EditIdentityForm _name='' _color={randomColor()} editIdentity={handleEditIdentityFormSubmit} />
    </ModalContainer>
  )
}
