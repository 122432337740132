/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import { useMatch } from 'react-router-dom'

import { makeMessageURL, isDraft } from '../dataModels/pointUtils'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'

import { loadMessages } from '../slices/published'

import { PageContainer } from './PageContainer'
import { SemanticScreenTopBar } from './SemanticScreenTopBar'
import { RegionsGrid } from './RegionsGrid'
import { LoadingPage } from './LoadingPage'

export const SemanticScreen = (): ReactElement => {
  const dispatch = useAppDispatch()

  const match = useMatch(':authorId/messages/docs/:messageId')
  let messageURL
  if (match !== null) {
    const { authorId, messageId } = match.params
    messageURL = makeMessageURL(authorId, messageId)
  }

  const messageExists = useAppSelector(({ drafts, published }) => {
    return (isDraft(messageURL) && drafts.allURLs.includes(messageURL)) ||
      (!isDraft(messageURL) && published.messages[messageURL] !== undefined)
  })

  if (!messageExists && isDraft(messageURL)) {
    return <LoadingPage>This draft does not exist</LoadingPage>
  }

  if (!messageExists && !isDraft(messageURL)) {
    // TODO: Timeout if the message cannot be found?
    dispatch(loadMessages({ messageURLs: [messageURL] }))
    return <LoadingPage>Loading published message...</LoadingPage>
  }

  return (
    <PageContainer topBarContents={<SemanticScreenTopBar messageURL={messageURL} />}>
      <RegionsGrid messageURL={messageURL} />
    </PageContainer>
  )
}
