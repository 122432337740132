/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { ReactElement } from 'react'
import { useDrop } from 'react-dnd'
import { PointShape } from 'ushin-db'

import './region.scss'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { draftPointCreate, pointsMoveWithinMessageThunk } from '../slices/drafts'
import { setExpandedRegion, toggleExpandedRegion } from '../slices/expandedRegion'
import { hoverOver } from '../slices/drag'

import { Point } from './Point'
import { AllShapes } from './AllShapes'

import { capitalizeWord, getMessageByURL, isDraft } from '../dataModels/pointUtils'
import { ItemTypes, DraggablePointType } from '../constants/React-Dnd'

interface Props {
  messageURL: string
  shape: PointShape
}

export const ShapeRegion = ({ messageURL, shape }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const hoverIndex = useAppSelector(({ drag }) => {
    if ((drag.context != null) &&
      drag.context.region === shape &&
      // Only set hoverIndex if the message is a draft
      isDraft(messageURL)
    ) return drag.context.index
  })

  const pointURLs = useAppSelector(({ drafts, published }) => {
    const { shapes } = getMessageByURL(messageURL, published, drafts)
    return shapes[shape]
  })

  const isExpanded = useAppSelector(({ expandedRegion }) => expandedRegion.region === shape)
  const isMinimized = useAppSelector(({ expandedRegion }) => expandedRegion.region !== null && !isExpanded)

  // TODO: Reuse logic between regionHeaderRef and expandRef
  // definitions
  const [, regionHeaderRef] = useDrop({
    accept: ItemTypes.POINT,
    hover: (item: DraggablePointType) => {
      if (item.region !== shape || item.index !== 0) {
        dispatch(hoverOver({ region: shape, index: 0 }))
      }

      item.index = 0
      item.region = shape
    },
    drop: () => {
      if (isDraft(messageURL)) {
        dispatch(pointsMoveWithinMessageThunk({ messageURL }))
      }
    }
  })

  const [, drop] = useDrop({
    accept: ItemTypes.POINT,
    hover: (item: DraggablePointType) => {
      const newIndex = pointURLs.length

      // Point wasn't already at the bottom of this region
      if (item.region !== shape || item.index !== pointURLs.length) {
        dispatch(hoverOver({ region: shape, index: newIndex }))

        item.index = newIndex
        item.region = shape
      }
    },
    drop: () => {
      if (isDraft(messageURL)) {
        dispatch(pointsMoveWithinMessageThunk({ messageURL }))
      }
    }
  })

  const [, expandRef] = useDrop({
    accept: ItemTypes.POINT,
    hover: () => {
      dispatch(setExpandedRegion({ region: shape }))
    }
  })

  const [, hoverLineRef] = useDrop({
    accept: ItemTypes.POINT,
    drop: () => {
      if (isDraft(messageURL)) {
        dispatch(pointsMoveWithinMessageThunk({ messageURL }))
      }
    }
  })

  const createEmptyPoint = (): void => {
    dispatch(draftPointCreate({ shape, index: pointURLs.length, messageURL, main: false }))
  }

  const onClickRemainingSpace = (): void => {
    dispatch(toggleExpandedRegion({ region: shape }))
    if (!isExpanded && isDraft(messageURL)) {
      createEmptyPoint()
    }
  }

  const listItems = pointURLs.map((url: string, i: number) => (
    <Point messageURL={messageURL} pointURL={url} region={shape} index={i} key={url} />
  ))

  if (hoverIndex !== undefined) {
    listItems.splice(
      hoverIndex,
      0,
      <div className='hover-line-wrapper' key='hover-line'>
        <div className='hover-line' ref={hoverLineRef} />
      </div>
    )
  }

  let regionClassName = 'region'
  if (isExpanded) regionClassName += ' expanded'

  return (
    <div className={regionClassName} ref={expandRef}>
      <div className='region-inner-container'>
        {!isMinimized && (
          <div
            className='region-header'
            onClick={() => dispatch(toggleExpandedRegion({ region: shape }))}
            ref={regionHeaderRef}
          >
            <AllShapes shape={shape} />
            {capitalizeWord(shape)}
          </div>
        )}
        {listItems}
        <div className='drop-target' ref={drop} onClick={onClickRemainingSpace} />
      </div>
    </div>
  )
}
