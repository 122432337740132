/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, useEffect, useRef } from 'react'
import { wrapGrid } from 'animate-css-grid'

import './regions-grid.scss'

import { expandedRegionStylesGenerator } from '../utils/stylesGenerators'

import { regions } from '../dataModels/dataModels'
import { extractAuthorURL, isDraft } from '../dataModels/pointUtils'

import { ShapeRegion } from './ShapeRegion'
import { TopRegion } from './TopRegion'
import { CenterRegion } from './CenterRegion'

import { useAppSelector } from '../hooks/useRedux'

interface Props {
  messageURL: string
}

export const RegionsGrid = ({ messageURL }: Props): ReactElement => {
  const regionsGridRef = useRef<HTMLDivElement>(null)

  const authorURL = extractAuthorURL(messageURL)
  const authorColor = useAppSelector(({ authors }) => {
    if (authorURL !== undefined) return authors.byURL[authorURL].color
  })

  const expandedRegion = useAppSelector(({ expandedRegion }) => expandedRegion.region)

  useEffect(() => {
    (regionsGridRef.current != null) &&
      wrapGrid(regionsGridRef.current, {
        duration: 150,
        easing: 'linear'
      })
  }, [])

  const { gridTemplateRows, gridTemplateColumns } = expandedRegionStylesGenerator(expandedRegion)

  let regionsGridClassName = 'regions-grid'
  if (isDraft(messageURL)) regionsGridClassName += ' draft'

  return (
    <div
      className={regionsGridClassName}
      style={{
        gridTemplateRows,
        gridTemplateColumns,
        backgroundColor: authorColor,
        borderColor: authorColor
      }}
      ref={regionsGridRef}
    >
      {regions.map((region) => {
        if (region === 'replyHistory') {
          return (
            <TopRegion
              messageURL={messageURL}
              key={region}
            />
          )
        }
        if (region === 'center') {
          return (
            <CenterRegion
              messageURL={messageURL}
              key={region}
            />
          )
        } else {
          return (
            <ShapeRegion
              messageURL={messageURL}
              shape={region}
              key={region}
            />
          )
        }
      })}
    </div>
  )
}
