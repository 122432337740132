/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { TrustArea, helpers } from 'ushin-db'
import { PersonCircle, ShieldFillX } from 'react-bootstrap-icons'

import './button.scss'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'

import { TRUST_LABELS, BLOCK, UNSET, getTrustLabel, getTrustWeight } from '../utils/trustHelpers'

import { setTrustWeight, unsetTrustWeight, addDistrusted } from '../slices/trust'

interface Props {
  fromAuthorURL: string
  toAuthorURL: string
  trustArea: TrustArea
  disabled: boolean
}

export const TrustRank = ({ fromAuthorURL, toAuthorURL, trustArea, disabled }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const weight = useAppSelector(({ trust }) => trust[fromAuthorURL].trustInfo[trustArea].weights[toAuthorURL])
  const isDistrusted = useAppSelector(({ trust }) => trust[fromAuthorURL].trustInfo[trustArea].distrusted.includes(toAuthorURL)) as boolean

  function handleTrustSelectChange (e: React.ChangeEvent<HTMLSelectElement>): void {
    const trustAssignment = e.target.value
    if (trustAssignment === BLOCK) {
      dispatch(addDistrusted({ fromAuthorURL, toAuthorURL, trustArea }))
    } else if (trustAssignment === UNSET) {
      dispatch(unsetTrustWeight({ fromAuthorURL, toAuthorURL, trustArea }))
    } else {
      dispatch(setTrustWeight({ fromAuthorURL, toAuthorURL, trustArea, weight: getTrustWeight(trustAssignment) }))
    }
  }

  let trustLabel = getTrustLabel(weight)
  if (isDistrusted) trustLabel = BLOCK

  let labelIcon: React.ReactNode = false
  if (trustArea === helpers.BLOCKER) labelIcon = <ShieldFillX />
  if (trustArea === helpers.SOURCE) labelIcon = <PersonCircle />

  return (
    <div className='trust-rank'>
      {fromAuthorURL !== toAuthorURL && (
        <>
          <label htmlFor={trustArea}>
            {labelIcon}
          </label>
          <select
            disabled={disabled}
            title='Select trust'
            id={trustArea}
            value={trustLabel}
            onChange={handleTrustSelectChange}
          >
            {TRUST_LABELS.map((opt) => (
              <option value={opt} key={opt}>{opt}</option>
            ))}
            {trustArea === helpers.SOURCE && <option value={BLOCK} key={BLOCK}>{BLOCK}</option>}
            <option value={UNSET} key={UNSET}>{UNSET}</option>
          </select>
        </>
      )}
    </div>
  )
}
