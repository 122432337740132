/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { DraftQuotePoint, PointShape } from 'ushin-db'

export const allPointShapes: PointShape[] = [
  'facts',
  'thoughts',
  'feelings',
  'needs',
  'topics',
  'actions',
  'people'
]

export type RegionI = PointShape | 'replyHistory' | 'center'

export const regions: RegionI[] = [
  'facts',
  'replyHistory',
  'people',
  'thoughts',
  'center',
  'actions',
  'feelings',
  'needs',
  'topics'
]

export function isPointShape (region: string): region is PointShape {
  return (allPointShapes as string[]).includes(region)
}

export type RegionWithParkingI = RegionI | 'parking'

export interface DraftQuotePointWithShape extends DraftQuotePoint {
  shape: PointShape
}

// TODO: validate that main points are contained in the points array
