/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
 */
import React, { ReactElement, useEffect } from 'react'
import { PersonCircle, ShieldFillX, XCircle } from 'react-bootstrap-icons'
import { useSearchParams } from 'react-router-dom'
import { helpers, TrustType } from 'ushin-db'

import './peer-list.scss'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { getTrustInfo, syncTrustParamsAndSearchParams } from '../slices/trust'
import { getAuthorInfo } from '../slices/authors'

import { setBooleanSearchParams } from '../utils/searchParamsHelpers'

import { Collapsible } from './Collapsible'
import { PeerListItem } from './PeerListItem'
import { TrustParameters } from './TrustParameters'

interface Props {
  authorURL: string
}

const sortWithUndefined = (url1: string, url2: string, weights: { [url: string]: number }): number => {
  // Put peers with undefined trust weight at the end
  const weight1 = weights[url1]
  const weight2 = weights[url2]

  if (weight1 === undefined && weight2 === undefined) return 0
  if (weight1 === undefined) return 1
  if (weight2 === undefined) return -1

  return weight2 - weight1
}

export const PeerList = ({ authorURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    dispatch(syncTrustParamsAndSearchParams({ authorURL, searchParams, setSearchParams }))
  }, [dispatch, authorURL, searchParams, setSearchParams])

  const trust = useAppSelector(({ trust }) => trust[authorURL])
  const isMissingTrustedAuthorInfo = useAppSelector(({ authors }) => {
    return trust === undefined ||
        Object.keys(trust.sourceURLHopsMapping).some(url => authors.byURL[url] === undefined) ||
        Object.keys(trust.blockerURLHopsMapping).some(url => authors.byURL[url] === undefined) ||
        Object.keys(trust.blockedButDirectSourceURLHopsMapping).some(url => authors.byURL[url] === undefined) ||
        trust.blockedURLs.some(url => authors.byURL[url] === undefined)
    // No need to check indirectSourceButBlockedURLHopsMapping because they are included in blockedURLs
  }) as boolean

  if (trust === undefined) {
    dispatch(getTrustInfo({ authorURLs: [authorURL] }))
    return <div className='peer-list-header'>Peers loading...</div>
  } else if (isMissingTrustedAuthorInfo) {
    dispatch(getAuthorInfo({
      authorURLs: [
        ...Object.keys(trust.sourceURLHopsMapping),
        ...Object.keys(trust.blockerURLHopsMapping),
        ...Object.keys(trust.blockedButDirectSourceURLHopsMapping),
        ...trust.blockedURLs
        // No need to check indirectSourceButBlockedURLHopsMapping because they are included in blockedURLs
      ]
    }))
    return <div className='peer-list-header'>Peers loading...</div>
  }

  const { trustInfo, sourceURLHopsMapping, blockerURLHopsMapping, blockedButDirectSourceURLHopsMapping, indirectSourceButBlockedURLHopsMapping, blockedURLs } = trust
  const { weights: blockerWeights } = trustInfo[helpers.BLOCKER]
  const { weights: sourceWeights } = trustInfo[helpers.SOURCE]

  // Filter out, then unshift authorURL to ensure it's at the beginning of sortedSourceURLs and sortedBlockerURLs
  const sortedSourceURLs = [...Object.keys(sourceURLHopsMapping), ...Object.keys(blockedButDirectSourceURLHopsMapping)].sort((url1, url2) => sortWithUndefined(url1, url2, sourceWeights)).filter(url => url !== authorURL)
  sortedSourceURLs.unshift(authorURL)

  const sortedBlockerURLs = Object.keys(blockerURLHopsMapping).sort((url1, url2) => sortWithUndefined(url1, url2, blockerWeights)).filter(url => url !== authorURL)
  sortedBlockerURLs.unshift(authorURL)

  const showAllBlocked = searchParams.has('allblocked')

  const displayBlockedURLs = showAllBlocked
    ? blockedURLs.filter(url => blockedButDirectSourceURLHopsMapping[url] === undefined)
    : Object.keys(indirectSourceButBlockedURLHopsMapping)

  function handleShowAllBlockedCheckBoxChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setBooleanSearchParams(searchParams, 'allblocked', !showAllBlocked)
    setSearchParams(searchParams, { replace: 'true' })
  }

  function handleShowAllBlockedContainerClick (e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation()
  }

  const blockersHeader = (
    <>
      <div className='peer-list-header'>
        <ShieldFillX />
        Blockers ({Object.keys(blockerURLHopsMapping).length})
      </div>
      <TrustParameters authorURL={authorURL} trustArea={helpers.BLOCKER} />
    </>
  )
  const blockedHeader = (
    <>
      <div className='peer-list-header'>
        <XCircle />
        Blocked ({displayBlockedURLs.length})
      </div>
      <div onClick={handleShowAllBlockedContainerClick}>
        <input
          type='checkbox'
          id='showAllBlocked'
          name='showAllBlocked'
          onChange={handleShowAllBlockedCheckBoxChange}
          checked={showAllBlocked}
        />
        <label htmlFor='showAllBlocked'>show all blocked</label>
      </div>
    </>
  )
  const sourcesHeader = (
    <>
      <div className='peer-list-header'>
        <PersonCircle />
        Sources ({sortedSourceURLs.length})
      </div>
      <TrustParameters authorURL={authorURL} trustArea={helpers.SOURCE} />
    </>
  )

  const mapURLToListItem = (url: string, trustType: TrustType): React.ReactNode => (
    <PeerListItem fromAuthorURL={authorURL} toAuthorURL={url} trustType={trustType} key={url} />
  )

  return (
    <>
      <Collapsible header={sourcesHeader} startOpen={false} content={sortedSourceURLs.map(url => mapURLToListItem(url, helpers.SOURCE))} />
      <Collapsible header={blockersHeader} startOpen={false} content={sortedBlockerURLs.map(url => mapURLToListItem(url, helpers.BLOCKER))} />
      <Collapsible header={blockedHeader} startOpen={false} content={displayBlockedURLs.map(url => mapURLToListItem(url, helpers.BLOCKED))} />
    </>
  )
}
