/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import './message-list-item.scss'

import { useAppDispatch } from '../hooks/useRedux'
import { draftMessageCreate } from '../slices/drafts'

interface Props {
  handleClick?: () => void
}

export const NewMessageButton = ({ handleClick }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  function handleWrapperClick (e: React.MouseEvent): void {
    if (handleClick === undefined) {
      dispatch(draftMessageCreate({ navigate }))
    } else {
      handleClick()
    }
  }

  return (
    <div
      className='new-message-button'
      onClick={handleWrapperClick}
    >
      <div className='new-message-text'>New Message</div>
    </div>
  )
}
