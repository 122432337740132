/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { ThreeDots } from 'react-bootstrap-icons'

import { getMessageByURL } from '../dataModels/pointUtils'

import { MessageListItem } from './MessageListItem'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { toggleExpandedRegion } from '../slices/expandedRegion'

interface Props {
  messageURL: string
}

export const TopRegion = ({ messageURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const replyHistory = useAppSelector(({ published, drafts }) => getMessageByURL(messageURL, published, drafts).replyHistory)

  const isExpanded = useAppSelector(({ expandedRegion }) => expandedRegion.region === 'replyHistory')

  const replyParent = replyHistory[replyHistory.length - 1]

  let replyHistoryContent
  if (replyHistory.length > 0) {
    if (isExpanded) {
      replyHistoryContent = replyHistory.map(({ messageURL, mainPointURL, subPointURL }) => (
        <MessageListItem
          messageURL={messageURL}
          mainPointURL={mainPointURL}
          subPointURLs={subPointURL !== undefined ? [subPointURL] : undefined}
          key={mainPointURL}
        />
      ))
    } else {
      const { messageURL, mainPointURL, subPointURL } = replyParent
      replyHistoryContent = (
        <>
          <ThreeDots className='spacer' />
          <MessageListItem
            messageURL={messageURL}
            mainPointURL={mainPointURL}
            subPointURLs={subPointURL !== undefined ? [subPointURL] : undefined}
          />
        </>
      )
    }
  }

  let regionClassName = 'top region'
  if (isExpanded) regionClassName += ' expanded'

  return (
    <div
      className={regionClassName}
      onClick={() => dispatch(toggleExpandedRegion({ region: 'replyHistory' }))}
      style={{ paddingBottom: '0.5rem' }}
    >
      <div className='region-inner-container'>
        {replyHistoryContent}
      </div>
    </div>
  )
}
