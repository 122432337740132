/*
  Copyright (C) 2020-22 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DraftPoint } from 'ushin-db'
import { AppThunk } from './index'
import { isQuote } from '../dataModels/pointUtils'
import { combinePoints } from '../slices/drafts'

interface Details {
  pointURL: string
  contentIndex: number
}

interface CursorPositionState {
  details: Details | null
}

const initialCursorPositionState: CursorPositionState = {
  details: null
}

const cursorPositionSlice = createSlice({
  name: 'cursorPosition',
  initialState: initialCursorPositionState,
  reducers: {
    jumpToPrevPoint: (state, action: PayloadAction<{
      prevPoint: DraftPoint
    }>) => {
      const { prevPoint } = action.payload

      state.details = {
        pointURL: prevPoint.url,
        contentIndex: prevPoint.content.length
      }
    },

    jumpToNextPoint: (state, action: PayloadAction<{
      nextPoint: DraftPoint
    }>) => {
      const { nextPoint } = action.payload

      state.details = {
        pointURL: nextPoint.url,
        contentIndex: 0
      }
    },

    clearCursorPosition: state => ({ details: null })
  },
  extraReducers: builder => builder
    .addCase(combinePoints, (state, action) => {
      const { pointToKeep } = action.payload

      state.details = {
        pointURL: pointToKeep.url,
        contentIndex: pointToKeep.content.length
      }
    })
})

interface JumpToPrevPointThunkPayload {
  messageURL: string
  shape: string
  index: number
}

export const jumpToPrevPointThunk = (
  payload: JumpToPrevPointThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { drafts } = getState()

    const { shape, messageURL, index } = payload

    const pointURLs = drafts.byURL[messageURL].present.message.shapes[shape]

    function getPrevPoint (i: number): DraftPoint | undefined {
      if (i === 0) return
      const pointURL = pointURLs[i - 1]
      const point = drafts.byURL[messageURL].present.points[pointURL]
      if (isQuote(point)) return getPrevPoint(i - 1) // Skip over quote points
      return point
    }

    const prevPoint = getPrevPoint(index)

    if (prevPoint !== undefined) {
      dispatch(jumpToPrevPoint({ prevPoint }))
    }
  }
}

interface JumpToNextPointThunkPayload {
  messageURL: string
  shape: string
  index: number
}

export const jumpToNextPointThunk = (
  payload: JumpToNextPointThunkPayload
): AppThunk => {
  return (dispatch, getState) => {
    const { drafts } = getState()

    const { shape, messageURL, index } = payload

    const pointURLs = drafts.byURL[messageURL].present.message.shapes[shape]

    function getNextPoint (i: number): DraftPoint | undefined {
      if (i === pointURLs.length - 1) return
      const pointURL = pointURLs[i + 1]
      const point = drafts.byURL[messageURL].present.points[pointURL]
      if (isQuote(point)) return getNextPoint(i + 1) // Skip over quote points
      return point
    }

    const nextPoint = getNextPoint(index)

    if (nextPoint !== undefined) {
      dispatch(jumpToNextPoint({ nextPoint }))
    }
  }
}

export const {
  clearCursorPosition
} = cursorPositionSlice.actions
export default cursorPositionSlice.reducer
