/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { Middleware } from '@reduxjs/toolkit'
import { RootState } from '../slices'
import { openCreateIdentityModal, openSelectIdentityModal, openSelectOrCreateIdentityModal } from '../slices/modal'
import { pinMessage, unpinMessage } from '../slices/pinnedMessages'
import { publishMessage } from '../slices/published'

export const currentIdentityExistsMiddleware: Middleware<{}, RootState> = storeAPI => next => async action => {
  const { currentIdentity, writableIdentities } = storeAPI.getState().authors

  if (openSelectOrCreateIdentityModal.match(action) ||
    (currentIdentity === undefined &&
      (publishMessage.match(action) || pinMessage.match(action) || unpinMessage.match(action)))) {
    if (Object.keys(writableIdentities).length > 0) {
      storeAPI.dispatch(openSelectIdentityModal())
    } else {
      storeAPI.dispatch(openCreateIdentityModal())
    }
    return
  }

  return next(action)
}
