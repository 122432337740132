/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { Middleware } from '@reduxjs/toolkit'
import { USHINBase } from 'ushin-db'
import { DraftsDB } from '../../draftsDB'
import {
  initDbs,
  initAppMiddlewareHelper,
  getAuthorInfoMiddlewareHelper,
  setAuthorInfoMiddlewareHelper,
  addIdentityMiddlewareHelper,
  getTrustInfoMiddlewareHelper,
  setTrustWeightMiddlewareHelper,
  unsetTrustWeightMiddlewareHelper,
  addDistrustedMiddlewareHelper,
  setMaxHopsMiddlewareHelper,
  setThresholdMiddlewareHelper,
  syncTrustParamsAndSearchParamsMiddlewareHelper,
  publishMessageMiddlewareHelper,
  loadMessagesMiddlewareHelper,
  searchMessagesMiddlewareHelper,
  continueSearchMessagesMiddlewareHelper,
  getPinnedMessagesMiddlewareHelper,
  pinMessageMiddlewareHelper,
  unpinMessageMiddlewareHelper
} from './dbHelpers'
import { RootState } from '../../slices'
import { initApp } from '../../slices/initApp'
import {
  getAuthorInfo,
  setAuthorInfo,
  addIdentity,
  selectIdentity
} from '../../slices/authors'
import {
  getTrustInfo,
  setTrustWeight,
  unsetTrustWeight,
  addDistrusted,
  setThreshold,
  setMaxHops,
  syncTrustParamsAndSearchParams
} from '../../slices/trust'
import { publishMessage, loadMessages } from '../../slices/published'
import { searchMessages, continueSearchMessages } from '../../slices/search'
import { setDrafts } from '../../slices/drafts'
import { getPinnedMessages, pinMessage, unpinMessage } from '../../slices/pinnedMessages'

export function createDbMiddleware (): Middleware<{}, RootState> {
  let db: USHINBase | null = null
  let draftsDB: DraftsDB | null = null

  return storeAPI => next => async action => {
    if (initApp.match(action)) {
      ({ db, draftsDB } = await initDbs())
      initAppMiddlewareHelper(action, { db, draftsDB }, storeAPI)
      return
    }

    if (db === null || draftsDB === null) throw new Error('Databases were not initialized')

    if (setDrafts.match(action)) {
      await draftsDB.setDrafts(action.payload.draftsState)
    } else if (selectIdentity.match(action)) {
      const { authorURL } = action.payload
      // TODO: better to put the localStorage events in some subscribe event?
      localStorage.setItem('currentIdentity', authorURL)
    } else if (getAuthorInfo.match(action)) {
      getAuthorInfoMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (setAuthorInfo.match(action)) {
      setAuthorInfoMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (addIdentity.match(action)) {
      addIdentityMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (getTrustInfo.match(action)) {
      getTrustInfoMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (setTrustWeight.match(action)) {
      setTrustWeightMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (unsetTrustWeight.match(action)) {
      unsetTrustWeightMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (addDistrusted.match(action)) {
      addDistrustedMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (setMaxHops.match(action)) {
      setMaxHopsMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (setThreshold.match(action)) {
      setThresholdMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (syncTrustParamsAndSearchParams.match(action)) {
      syncTrustParamsAndSearchParamsMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (publishMessage.match(action)) {
      publishMessageMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (loadMessages.match(action)) {
      loadMessagesMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (searchMessages.match(action)) {
      searchMessagesMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (continueSearchMessages.match(action)) {
      continueSearchMessagesMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (getPinnedMessages.match(action)) {
      getPinnedMessagesMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (pinMessage.match(action)) {
      pinMessageMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    } else if (unpinMessage.match(action)) {
      unpinMessageMiddlewareHelper(action, { db, draftsDB }, storeAPI)
    }

    return next(action)
  }
}
