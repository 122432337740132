import { helpers } from 'ushin-db'
import { isInt } from './index'
import { RecursivePartial } from './types'

// Trust labels are based on this table from https://cblgh.org/trustnet/

// Trust weight (t)  Semantic         Human-meaningful label
// ----------------------------------------------------------
// t == 0            no trust         New person
// 0 < t < 0.25      low trust        Acquaintance
// 0.25 >= t < 0.75  medium trust     Friend
// 0.75 >= t < 1.0   high trust       Peer
// t == 1            complete trust   Partner

export const TRUST_LABELS = ['full', 'high', 'mid', 'low']
export const BLOCK = 'block'
export const UNSET = 'unset'
export const SELF = 'self'

export const MIN_POSSIBLE_HOPS = 0
export const MAX_POSSIBLE_HOPS = 6

export interface TrustParams {
  maxHops: {
    [helpers.SOURCE]: number
    [helpers.BLOCKER]: number
  }
  threshold: {
    [helpers.SOURCE]: number
    [helpers.BLOCKER]: number
  }
}

export const DEFAULT_MAX_HOPS = {
  [helpers.SOURCE]: 1,
  [helpers.BLOCKER]: 1
}
export const DEFAULT_THRESHOLD = {
  [helpers.SOURCE]: 0.5,
  [helpers.BLOCKER]: 0.75
}

export function getTrustLabel (weight: number): string {
  if (weight === undefined) {
    return UNSET
  } else if (weight <= 0.25) {
    return TRUST_LABELS[3]
  } else if (weight <= 0.5) {
    return TRUST_LABELS[2]
  } else if (weight <= 0.75) {
    return TRUST_LABELS[1]
  } else if (weight <= 1) {
    return TRUST_LABELS[0]
  }
  throw new Error('Provide a trustWeight from 0-1')
}

export function getTrustWeight (label: string): number {
  if (label === TRUST_LABELS[3]) {
    return 0.25
  } else if (label === TRUST_LABELS[2]) {
    return 0.5
  } else if (label === TRUST_LABELS[1]) {
    return 0.75
  } else if (label === TRUST_LABELS[0]) {
    return 1
  }
  throw new Error(`Provide one of these labels: ${TRUST_LABELS.toString()} instead of\n${label}`)
}

export function isTrustArea (possibleTrustArea: string): possibleTrustArea is TrustArea {
  return (possibleTrustArea === helpers.BLOCKER || possibleTrustArea === helpers.SOURCE)
}

// This function validates the trust params stored in the query string
export function getTrustParamsFromSearchParams (searchParams: URLSearchParams): RecursivePartial<TrustParams> {
  const threshold = {}
  const maxHops = {}

  const rawSourceThreshold = searchParams.get('sourcethreshold')
  const rawBlockerThreshold = searchParams.get('blockerthreshold')
  const rawSourceMaxHops = searchParams.get('sourcemaxhops')
  const rawBlockerMaxHops = searchParams.get('blockermaxhops')

  if (rawSourceThreshold !== null) {
    try {
      threshold[helpers.SOURCE] = getTrustWeight(rawSourceThreshold)
    } catch {
      console.log(`Invalid sourcethreshold: ${rawSourceThreshold}`)
    }
  }

  if (rawBlockerThreshold !== null) {
    try {
      threshold[helpers.BLOCKER] = getTrustWeight(rawBlockerThreshold)
    } catch {
      console.log(`Invalid blockerthreshold: ${rawBlockerThreshold}`)
    }
  }

  if (rawSourceMaxHops !== null) {
    try {
      maxHops[helpers.SOURCE] = validateMaxHops(rawSourceMaxHops)
    } catch {
      console.log(`Invalid sourcemaxhops: ${rawSourceMaxHops}`)
    }
  }

  if (rawBlockerMaxHops !== null) {
    try {
      maxHops[helpers.BLOCKER] = validateMaxHops(rawBlockerMaxHops)
    } catch {
      console.log(`Invalid blockermaxhops: ${rawBlockerMaxHops}`)
    }
  }

  return { threshold, maxHops }
}

function validateMaxHops (maxHopsString: string): number {
  const maxHops = parseInt(maxHopsString, 10)
  if (!isInt(maxHops) || maxHops < MIN_POSSIBLE_HOPS || maxHops > MAX_POSSIBLE_HOPS) throw new Error(`invalid maxHops: ${maxHopsString}`)
  return maxHops
}
