/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PinnedMessagesToLoad } from 'ushin-db'

interface PinnedMessagesState {
  [authorURL: string]: string[]
}

const initialPinnedMessagesState: PinnedMessagesState = {}

const pinnedMessagesSlice = createSlice({
  name: 'pinnedMessages',
  initialState: initialPinnedMessagesState,
  reducers: {
    getPinnedMessages: (state, action: PayloadAction<{
      authorURLs: string[]
    }>) => state,

    getPinnedMessagesSuccess: (state, action: PayloadAction<{
      pinnedMessagesToLoad: PinnedMessagesToLoad
    }>) => {
      Object.assign(state, action.payload.pinnedMessagesToLoad)
    },

    pinMessage: (state, action: PayloadAction<{
      messageURL: string
    }>) => state,

    pinMessageSuccess: (state, action: PayloadAction<{
      pinnedMessagesToLoad: PinnedMessagesToLoad
    }>) => {
      Object.assign(state, action.payload.pinnedMessagesToLoad)
    },

    unpinMessage: (state, action: PayloadAction<{
      messageURL: string
    }>) => state,

    unpinMessageSuccess: (state, action: PayloadAction<{
      pinnedMessagesToLoad: PinnedMessagesToLoad
    }>) => {
      Object.assign(state, action.payload.pinnedMessagesToLoad)
    }
  }
})

export const {
  getPinnedMessages,
  getPinnedMessagesSuccess,
  pinMessage,
  pinMessageSuccess,
  unpinMessage,
  unpinMessageSuccess
} = pinnedMessagesSlice.actions
export default pinnedMessagesSlice.reducer
