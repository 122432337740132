/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getPinnedMessages } from '../slices/pinnedMessages'
import { loadMessages } from '../slices/published'

import { MessageListItem } from './MessageListItem'

interface Props {
  authorURL: string
}

export const PinnedMessages = ({ authorURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const pinnedMessages = useAppSelector(({ pinnedMessages, published }) => {
    if (pinnedMessages[authorURL] === undefined) return
    return Object.values(pinnedMessages[authorURL]).map((url) => ({ url, message: published.messages[url] }))
  })

  if (pinnedMessages === undefined) {
    dispatch(getPinnedMessages({ authorURLs: [authorURL] }))
    return null
  }

  const missingMessages = pinnedMessages.filter(({ message }) => message === undefined)
  if (missingMessages.length > 0) {
    dispatch(loadMessages({ messageURLs: missingMessages.map(({ url }) => url) }))
    return null
  }

  return (
    <>
      {pinnedMessages.map(({ message }) => (
        <MessageListItem messageURL={message.url} mainPointURL={message.main} key={message.url} />
      ))}
    </>
  )
}
