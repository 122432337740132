/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, useState } from 'react'

import './edit-identity-form.scss'

interface Props {
  _name: string
  _color: string
  editIdentity: (name: string, color: string) => void
}

export const EditIdentityForm = ({ _name, _color, editIdentity }: Props): ReactElement => {
  const [name, setName] = useState(_name)
  const [color, setColor] = useState(_color)

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
  }

  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setColor(e.target.value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (name.length === 0) {
      window.alert('Please enter a name.')
    } else {
      editIdentity(name, color)
    }
  }

  return (
    <form className='edit-identity' onSubmit={handleSubmit}>
      <label htmlFor='name'>Enter handle:</label>
      <input type='text' value={name} placeholder='Your handle' id='name' onChange={handleChangeName} />
      <label htmlFor='color'>Select a color:</label>
      <input type='color' value={color} id='color' onChange={handleChangeColor} />
      <button className='button submit' type='submit' title='Create'>
        Submit
      </button>
    </form>
  )
}
