/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { PeopleSvg } from '../components/AllShapes'

export function bannerBackgroundGenerator (color: string, darkMode: boolean): {
  backgroundImageAuthorColor: string
  backgroundImageColorFG: string
} {
  // HACK: Color values are repeated here as hardcoded values. Better
  // solution: initialize color constants in javascript, then create css
  // variables from those constants. The constants can also be imported here.
  const fillColor = darkMode ? 'black' : 'white'

  const authorStrokeColor = encodeURIComponent(color)
  const strokeColorFG = darkMode ? 'white' : 'black'

  return {
    backgroundImageAuthorColor: makeBannerBackground(fillColor, authorStrokeColor),
    backgroundImageColorFG: makeBannerBackground(fillColor, strokeColorFG)
  }
}

function makeBannerBackground (fillColor: string, strokeColor: string): string {
  // var() can not be interpolated in url() in CSS, so we have to
  // create a new url in javascript
  return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' preserveAspectRatio='none'> <path d='${PeopleSvg().props.d as string}' fill='${fillColor}' stroke='${strokeColor}' stroke-width='1.5' vector-effect='non-scaling-stroke'/></svg>")`
}

export function expandedRegionStylesGenerator (expandedRegion: string | null): {
  gridTemplateColumns: string
  gridTemplateRows: string
} {
  let gridTemplateColumns = '1fr 1fr 1fr'
  let gridTemplateRows = '1fr 1fr 1fr'

  if (expandedRegion === null) return { gridTemplateRows, gridTemplateColumns }

  const expandedSize = '4fr'
  const minimizedSize = '1fr'

  const top = 'facts replyHistory people'
  const centerX = 'thoughts center actions'
  const bottom = 'feelings needs topics'

  const left = 'facts thoughts feelings'
  const centerY = 'replyHistory center needs'
  const right = 'people actions topics'

  if (top.includes(expandedRegion)) {
    gridTemplateRows = `${expandedSize} ${minimizedSize} ${minimizedSize}`
  } else if (centerX.includes(expandedRegion)) {
    gridTemplateRows = `${minimizedSize} ${expandedSize} ${minimizedSize}`
  } else if (bottom.includes(expandedRegion)) {
    gridTemplateRows = `${minimizedSize} ${minimizedSize} ${expandedSize}`
  }

  if (left.includes(expandedRegion)) {
    gridTemplateColumns = `${expandedSize} ${minimizedSize} ${minimizedSize}`
  } else if (centerY.includes(expandedRegion)) {
    gridTemplateColumns = `${minimizedSize} ${expandedSize} ${minimizedSize}`
  } else if (right.includes(expandedRegion)) {
    gridTemplateColumns = `${minimizedSize} ${minimizedSize} ${expandedSize}`
  }

  return { gridTemplateRows, gridTemplateColumns }
}
