/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { Middleware } from '@reduxjs/toolkit'
import { RootState } from '../../slices'
import {
  draftMessageCreate,
  draftMessageDelete,
  replyToPoint,
  movePointsFromMessage,
  quotePointsFromMessage
} from '../../slices/drafts'
import { removeHyperPrefix } from '../../dataModels/pointUtils'
import { publishMessageSuccess } from '../../slices/published'

export const routerMiddleware: Middleware<{}, RootState> = storeAPI => next => async action => {
  const navigate = action.payload?.navigate

  if (draftMessageCreate.match(action)) {
    const { url } = action.payload
    navigate(`/${removeHyperPrefix(url)}`)
  } else if (draftMessageDelete.match(action)) {
    const completedAction = next(action)
    const nextDraftMessageURL = storeAPI.getState().drafts.allURLs[0]
    navigate(removeHyperPrefix(`/${nextDraftMessageURL}`))
    return completedAction
  } else if (replyToPoint.match(action)) {
    const { replyMessageURL } = action.payload
    navigate(removeHyperPrefix(`/${replyMessageURL}`))
  } else if (movePointsFromMessage.match(action)) {
    const { toMessageURL } = action.payload
    navigate(removeHyperPrefix(`/${toMessageURL}`))
  } else if (quotePointsFromMessage.match(action)) {
    const { toMessageURL } = action.payload
    navigate(removeHyperPrefix(`/${toMessageURL}`))
  } else if (publishMessageSuccess.match(action)) {
    const { messageStore } = action.payload
    const messageURL = Object.keys(messageStore)[0]
    navigate(`/${removeHyperPrefix(messageURL)}`)
  }
  return next(action)
}
