/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'

import { containsPoints, makeMessageURL } from '../dataModels/pointUtils'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { moveOrQuotePointsFromMessageThunk, replyToPointThunk } from '../slices/drafts'

import { MessageListItem } from './MessageListItem'
import { NewMessageButton } from './NewMessageButton'

interface Props {
  action?: 'movePoints' | 'replyToPoint'
}

export const DraftMessages = ({ action }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const match = useMatch(':authorId/messages/docs/:messageId')

  let currentMessageURL
  if (match !== null) {
    const { authorId, messageId } = match.params
    currentMessageURL = makeMessageURL(authorId, messageId)
  }

  const displayMessages = useAppSelector(({ drafts }) => {
    const nonEmptyMessageURLs = drafts.allURLs.filter((url) =>
      containsPoints(drafts.byURL[url].present.message)
    )

    return nonEmptyMessageURLs.map(url => {
      const { main, shapes } = drafts.byURL[url].present.message
      return { url, displayPointURL: main ?? Object.values(shapes).flat()[0] }
    })
  })

  let handleMessageListItemClick: ((messageURL: string) => void) | undefined
  let handleNewMessageButtonClick: (() => void) | undefined

  if (action === 'movePoints') {
    handleMessageListItemClick = (messageURL: string) => {
      dispatch(moveOrQuotePointsFromMessageThunk({
        toMessageURL: messageURL,
        fromMessageURL: currentMessageURL,
        navigate
      }))
    }

    handleNewMessageButtonClick = (): void => {
      dispatch(moveOrQuotePointsFromMessageThunk({
        fromMessageURL: currentMessageURL,
        navigate
      }))
    }
  } else if (action === 'replyToPoint') {
    handleMessageListItemClick = (messageURL: string) => {
      dispatch(replyToPointThunk({
        replyMessageURL: messageURL,
        originalMessageURL: currentMessageURL,
        navigate
      }))
    }

    handleNewMessageButtonClick = (): void => {
      dispatch(replyToPointThunk({
        originalMessageURL: currentMessageURL,
        navigate
      }))
    }
  }

  return (
    <>
      <NewMessageButton handleClick={handleNewMessageButtonClick} />
      {displayMessages.map(({ url, displayPointURL }) => (
        <MessageListItem
          messageURL={url}
          mainPointURL={displayPointURL}
          handleClick={handleMessageListItemClick}
          key={url}
        />
      ))}
    </>
  )
}
