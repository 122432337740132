/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { Author, AuthorStore, helpers, TrustArea, USHINBase } from 'ushin-db'
import { DraftsDB } from '../../draftsDB'
import { RootState } from '../../slices'
import { initAppSuccess } from '../../slices/initApp'
import {
  getAuthorInfoSuccess,
  setAuthorInfoSuccess,
  addIdentitySuccess
} from '../../slices/authors'
import {
  AuthorTrustParams,
  getTrustInfoSuccess,
  setThresholdSuccess,
  setMaxHopsSuccess,
  syncTrustParamsAndSearchParamsSuccess
} from '../../slices/trust'
import { publishMessageSuccess, loadMessagesSuccess } from '../../slices/published'
import { addSearchResultMessage, allowContinueSearchMessages, doneSearchMessages } from '../../slices/search'
import { getPinnedMessagesSuccess, pinMessageSuccess, unpinMessageSuccess } from '../../slices/pinnedMessages'
import { DEFAULT_MAX_HOPS, DEFAULT_THRESHOLD, getTrustParamsFromSearchParams } from '../../utils/trustHelpers'

const SEARCH_LIMIT = 20

interface DBs {
  db: USHINBase
  draftsDB: DraftsDB
}

type MiddlewareHelper = (action: PayloadAction<any>, db: DBs, storeAPI: MiddlewareAPI<Dispatch, RootState>) => void

export async function initDbs (): Promise<{ db: USHINBase, draftsDB: DraftsDB }> {
  const db = await USHINBase.create({
    swarmOpts: {
      // This should prevent us from trying to reconnect constantly
      // Effectively disables the proxy
      wsReconnectDelay: Infinity,
      simplePeer: {
        config: {
          iceServers: [
            {
              urls: 'stun:openrelay.metered.ca:80'
            },
            {
              urls: 'turn:openrelay.metered.ca:80',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            }
          ]
        }
      }
    }
  })

  await db.init();

  // For debugging
  (window as any).db = db

  const draftsDB = new DraftsDB()

  return { db, draftsDB }
}

export const initAppMiddlewareHelper: MiddlewareHelper = async (action, { db, draftsDB }, storeAPI) => {
  const { authors } = storeAPI.getState()

  // Get draft points and messages
  const draftsState = await draftsDB.getDrafts()

  const allDraftMessages = {}
  const allDraftPoints = {}
  for (const url in draftsState.byURL) {
    const { past, present, future } = draftsState.byURL[url]

    for (const { message, points } of [...past, present, ...future]) {
      Object.assign(allDraftMessages, helpers.makeStore([message]))
      Object.assign(allDraftPoints, points)
    }
  }

  // Pass {} instead of authors.byURL because authors.byURL is always empty at startup
  const { authorStore, pointStore } = await db.getAuthorsAndPointsForDraftMessages(
    allDraftMessages, {}, allDraftPoints
  )

  // Get author info for writable identities whose data we didn't already get.
  const writableIdentityURLs = Object.keys(authors.writableIdentities)
  for (const url of writableIdentityURLs) {
    if (authorStore[url] === undefined) {
      Object.assign(authorStore, {
        [url]: await db.getAuthorInfo(url)
      })
    }
  }

  // Get trust info for all writable identities
  const trustToLoad = await db.getTrustBundle(makeAuthorTrustParams(writableIdentityURLs, storeAPI))

  storeAPI.dispatch(initAppSuccess({
    draftsState,
    authorStore,
    pointStore,
    trustToLoad
  }))
}

export const getAuthorInfoMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const authors = await Promise.all(
    action.payload.authorURLs.map(async (url: string) => await db.getAuthorInfo(url))
  ) as Author[]

  const authorStore = authors.reduce<AuthorStore>((store, author) => {
    store[author.url] = author
    return store
  }, {})

  storeAPI.dispatch(getAuthorInfoSuccess({ authorStore }))
}

export const setAuthorInfoMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { url, name, color } = action.payload
  const author = await _setDBAuthorInfo(db, url, name, color)
  storeAPI.dispatch(setAuthorInfoSuccess({ author }))
}

export const addIdentityMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { name, color } = action.payload

  const { authors } = storeAPI.getState()
  const { writableIdentities } = authors

  // Generate a new shortFormURL by incrementing the largest authorURL
  const identityCounterArray = Object.values(writableIdentities).map(i => Number(helpers.getIdFromHyperURL(i)))

  // Math.max.apply(null, []) returns -Infinity, so we must handle the empty array for the first identity added
  let largestCounter = identityCounterArray.length > 0 ? Math.max.apply(null, identityCounterArray) : -1
  largestCounter++

  const shortFormURL = helpers.getHyperURLFromId(String(largestCounter))

  const author = await _setDBAuthorInfo(db, shortFormURL, name, color)

  // TODO: store writableIdentities in ushin-db, not localStorage
  localStorage.setItem('writableIdentities', JSON.stringify({ ...writableIdentities, [author.url]: shortFormURL }))
  localStorage.setItem('currentIdentity', author.url)

  const trustToLoad = await db.getTrustBundle({
    [author.url]: {
      maxHops: DEFAULT_MAX_HOPS,
      threshold: DEFAULT_THRESHOLD
    }
  })

  // Batch these actions because the app expects a trust data to exist for each writable identity
  batch(() => {
    storeAPI.dispatch(getTrustInfoSuccess({ trustToLoad }))
    storeAPI.dispatch(addIdentitySuccess({ author: author, shortFormURL }))
  })
}

export const getTrustInfoMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const authorURLs = action.payload.authorURLs as string[]

  const trustToLoad = await db.getTrustBundle(makeAuthorTrustParams(authorURLs, storeAPI))

  storeAPI.dispatch(getTrustInfoSuccess({ trustToLoad }))
}

export const setTrustWeightMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { fromAuthorURL, toAuthorURL, trustArea, weight } = action.payload

  const { trust } = storeAPI.getState()

  await db.setTrustWeight(fromAuthorURL, toAuthorURL, trustArea, weight)
  if (
    // TODO: Fix type of middleware helpers so that we don't have to use type assertion here
    // TODO: Alternatively, refactor USHINAccount setTrustWeight method to do this
    trust[fromAuthorURL].trustInfo[trustArea as TrustArea].distrusted.includes(toAuthorURL) as boolean
  ) {
    await db.removeDistrusted(fromAuthorURL, toAuthorURL, trustArea)
  }

  const trustToLoad = await db.getTrustBundle(makeAuthorTrustParams([fromAuthorURL], storeAPI))

  storeAPI.dispatch(getTrustInfoSuccess({ trustToLoad }))
}

export const unsetTrustWeightMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { fromAuthorURL, toAuthorURL, trustArea } = action.payload

  const { trust } = storeAPI.getState()

  await db.unsetTrustWeight(fromAuthorURL, toAuthorURL, trustArea)
  if (
    // TODO: Fix type of middleware helpers so that we don't have to use type assertion here
    // TODO: Alternatively, refactor USHINAccount unsetTrustWeight method to do this
    trust[fromAuthorURL].trustInfo[trustArea as TrustArea].distrusted.includes(toAuthorURL) as boolean
  ) {
    await db.removeDistrusted(fromAuthorURL, toAuthorURL, trustArea)
  }

  const trustToLoad = await db.getTrustBundle(makeAuthorTrustParams([fromAuthorURL], storeAPI))

  storeAPI.dispatch(getTrustInfoSuccess({ trustToLoad }))
}

export const addDistrustedMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { fromAuthorURL, toAuthorURL, trustArea } = action.payload

  const { trust } = storeAPI.getState()

  await db.addDistrusted(fromAuthorURL, toAuthorURL, trustArea)
  if (trust[fromAuthorURL].trustInfo[trustArea as TrustArea].weights[toAuthorURL] !== undefined) {
    await db.unsetTrustWeight(fromAuthorURL, toAuthorURL, trustArea)
  }

  const trustToLoad = await db.getTrustBundle(makeAuthorTrustParams([fromAuthorURL], storeAPI))

  storeAPI.dispatch(getTrustInfoSuccess({ trustToLoad }))
}

export const syncTrustParamsAndSearchParamsMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  // Don't sync if we're already in the middle of syncing
  // This prevents an error which arose when toggling a search param value quickly,
  // causing both syncTrustParamsAndSearchParams and syncTrustParamsAndSearchParamsSuccess to fire simultaneously with alternating values (the previous value and the new value).
  // The two would run over and over and cause the UI to flash.
  if (storeAPI.getState().trust.pendingSyncSearchParams) return

  const { authorURL, searchParams, setSearchParams } = action.payload

  const { threshold, maxHops } = getTrustParamsFromSearchParams(searchParams)

  const authorTrustParams = makeAuthorTrustParams([authorURL], storeAPI)

  // When a threshold or maxHops values is undefined, that means that the query string contained an invalid value
  if (threshold[helpers.SOURCE] !== undefined) {
    authorTrustParams[authorURL].threshold[helpers.SOURCE] = threshold[helpers.SOURCE]
  }

  if (maxHops[helpers.SOURCE] !== undefined) {
    authorTrustParams[authorURL].maxHops[helpers.SOURCE] = maxHops[helpers.SOURCE]
  }

  if (threshold[helpers.BLOCKER] !== undefined) {
    authorTrustParams[authorURL].threshold[helpers.BLOCKER] = threshold[helpers.BLOCKER]
  }

  if (maxHops[helpers.BLOCKER] !== undefined) {
    authorTrustParams[authorURL].maxHops[helpers.BLOCKER] = maxHops[helpers.BLOCKER]
  }

  const trustToLoad = await db.getTrustBundle(authorTrustParams)

  storeAPI.dispatch(syncTrustParamsAndSearchParamsSuccess({ authorURL, searchParams, setSearchParams, authorTrustParams, trustToLoad }))
}

export const setMaxHopsMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authorURL, trustArea, maxHops, searchParams, setSearchParams } = action.payload

  const authorTrustParams = makeAuthorTrustParams([authorURL], storeAPI)

  authorTrustParams[authorURL].maxHops[trustArea as TrustArea] = maxHops

  const trustToLoad = await db.getTrustBundle(authorTrustParams)

  storeAPI.dispatch(setMaxHopsSuccess({ authorURL, trustArea, maxHops, trustToLoad, searchParams, setSearchParams }))
}

export const setThresholdMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authorURL, trustArea, threshold, searchParams, setSearchParams } = action.payload

  const authorTrustParams = makeAuthorTrustParams([authorURL], storeAPI)

  authorTrustParams[authorURL].threshold[trustArea as TrustArea] = threshold

  const trustToLoad = await db.getTrustBundle(authorTrustParams)

  storeAPI.dispatch(setThresholdSuccess({ authorURL, trustArea, threshold, trustToLoad, searchParams, setSearchParams }))
}

export const publishMessageMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authors, published, drafts } = storeAPI.getState()

  const { draftMessageURL, navigate } = action.payload
  const { message: draftMessage, points: draftPointStore } = drafts.byURL[draftMessageURL].present

  const { currentIdentity } = authors

  if (draftMessage.main === undefined) {
    window.alert('Before publishing, please add a main point to your message')
    return
  }

  const messageURL = await db.addMessage(currentIdentity, draftMessage, draftPointStore)

  const messageStore = await db.getMessages([messageURL])
  const { pointStore } = await db.getAuthorsAndPointsForPublishedMessages(
    messageStore, authors.byURL, published.points
  )

  storeAPI.dispatch(publishMessageSuccess({ draftMessageURL, messageStore, pointStore, navigate }))
}

export const loadMessagesMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authors, published } = storeAPI.getState()

  const { messageURLs } = action.payload

  const messageStore = await db.getMessages(messageURLs)

  const { authorStore, pointStore } = await db.getAuthorsAndPointsForPublishedMessages(
    messageStore, authors.byURL, published.points
  )

  storeAPI.dispatch(loadMessagesSuccess({ messageStore, authorStore, pointStore }))
}

export const searchMessagesMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { search, authors, published, trust } = storeAPI.getState()

  const { searchQuery, searchAsPeerURL } = search

  let searchDbURLs: string[] | undefined
  if (searchAsPeerURL !== undefined && trust[searchAsPeerURL] !== undefined) {
    searchDbURLs = Object.keys(trust[searchAsPeerURL].sourceURLHopsMapping)
  }

  const searchMessagesPuller = db.searchMessages(searchQuery, searchDbURLs)

  let count = 0
  let moreMessagesAvailable = true
  while (count++ < SEARCH_LIMIT) {
    const { value, done } = await searchMessagesPuller.next()
    if (done as boolean) {
      storeAPI.dispatch(doneSearchMessages())
      moreMessagesAvailable = false
      break
    }
    const messageStore = { [value.url]: value }
    // TODO: Possible performance issue of calling db.getAuthorsAndPointsForPublishedMessages each time we yield a new search result
    const { authorStore, pointStore } = await db.getAuthorsAndPointsForPublishedMessages(
      messageStore, authors.byURL, published.points
    )

    storeAPI.dispatch(
      addSearchResultMessage({
        result: value,
        messageStore,
        authorStore,
        pointStore
      })
    )
  }

  if (moreMessagesAvailable) storeAPI.dispatch(allowContinueSearchMessages())
}

export const continueSearchMessagesMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authors, published } = storeAPI.getState()

  const searchMessagesPuller = db.continueSearchMessages()

  let count = 0
  let moreMessagesAvailable = true
  while (count++ < SEARCH_LIMIT) {
    const { value, done } = await searchMessagesPuller.next()
    if (done as boolean) {
      storeAPI.dispatch(doneSearchMessages())
      moreMessagesAvailable = false
      break
    }
    const messageStore = { [value.url]: value }
    // TODO: Possible performance issue of calling db.getAuthorsAndPointsForPublishedMessages each time we yield a new search result
    const { authorStore, pointStore } = await db.getAuthorsAndPointsForPublishedMessages(
      messageStore, authors.byURL, published.points
    )

    storeAPI.dispatch(
      addSearchResultMessage({
        result: value,
        messageStore,
        authorStore,
        pointStore
      })
    )
  }

  if (moreMessagesAvailable) storeAPI.dispatch(allowContinueSearchMessages())
}

export const getPinnedMessagesMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { authorURLs } = action.payload

  const pinnedMessagesToLoad = await db.getPinnedMessages(authorURLs)

  storeAPI.dispatch(getPinnedMessagesSuccess({ pinnedMessagesToLoad }))
}

export const pinMessageMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { currentIdentity } = storeAPI.getState().authors

  const { messageURL } = action.payload

  const pinnedMessagesToLoad = await db.pinMessage(currentIdentity, messageURL)

  storeAPI.dispatch(pinMessageSuccess({ pinnedMessagesToLoad }))
}

export const unpinMessageMiddlewareHelper: MiddlewareHelper = async (action, { db }, storeAPI) => {
  const { currentIdentity } = storeAPI.getState().authors

  const { messageURL } = action.payload

  const pinnedMessagesToLoad = await db.unpinMessage(currentIdentity, messageURL)

  storeAPI.dispatch(unpinMessageSuccess({ pinnedMessagesToLoad }))
}

async function _setDBAuthorInfo (db: USHINBase, authorURL: string, name: string, color: string): Promise<Author> {
/* eslint-enable @typescript-eslint/no-invalid-void-type */
  await db.setAuthorInfo(authorURL, { name, color })
  const author = (await db.getAuthorInfo(authorURL))

  if (author.name === undefined || author.color === undefined) console.warn('Something went wrong, author lacks name or color')

  return author as Author
}

function makeAuthorTrustParams (authorURLs: string[], storeAPI: MiddlewareAPI<Dispatch, RootState>): AuthorTrustParams {
  return authorURLs.reduce<AuthorTrustParams>((params, url) => {
    const trust = storeAPI.getState().trust[url]

    const maxHopsReference = trust !== undefined ? trust.maxHops : DEFAULT_MAX_HOPS
    const thresholdReference = trust !== undefined ? trust.threshold : DEFAULT_THRESHOLD

    params[url] = {
      maxHops: { ...maxHopsReference },
      threshold: { ...thresholdReference }
    }
    return params
  }, {})
}
