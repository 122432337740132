/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
 */
import React, { ReactElement } from 'react'
import { TrustArea } from 'ushin-db'
import { useSearchParams } from 'react-router-dom'

import './trust-parameters.scss'

import { TRUST_LABELS, getTrustLabel, getTrustWeight } from '../utils/trustHelpers'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { setThreshold, setMaxHops } from '../slices/trust'

const MAX_HOPS_OPTIONS = [0, 1, 2, 3, 4, 5, 6]

interface Props {
  authorURL: string
  trustArea: TrustArea
}

export const TrustParameters = ({ authorURL, trustArea }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const maxHops = useAppSelector(({ trust }) => trust?.[authorURL].maxHops[trustArea])
  const threshold = useAppSelector(({ trust }) => trust?.[authorURL].threshold[trustArea])

  function handleMaxHopsSelectChange (e: React.ChangeEvent<HTMLSelectElement>): void {
    const maxHops = Number(e.target.value)
    dispatch(setMaxHops({ authorURL, trustArea, maxHops, searchParams, setSearchParams }))
  }

  function handleTrustThresholdSelectChange (e: React.ChangeEvent<HTMLSelectElement>): void {
    const threshold = getTrustWeight(e.target.value)
    dispatch(setThreshold({ authorURL, trustArea, threshold, searchParams, setSearchParams }))
  }

  function handleTrustParametersClick (e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation()
  }

  return (
    <div onClick={handleTrustParametersClick}>
      <label className='trust-parameters-label' htmlFor='threshold'>rank ≥</label>
      <select title='Set threshold' id='threshold' value={getTrustLabel(threshold)} onChange={handleTrustThresholdSelectChange}>
        {TRUST_LABELS.map((opt) => (
          <option value={opt} key={opt}>{opt}</option>
        ))}
      </select>
      <label className='trust-parameters-label' htmlFor='max-hops'>hops ≤</label>
      <select title='Set max hops' id='max-hops' value={maxHops} onChange={handleMaxHopsSelectChange}>
        {MAX_HOPS_OPTIONS.map((opt) => (
          <option value={opt} key={opt}>{opt}</option>
        ))}
      </select>
    </div>
  )
}
