/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Point as PointI, DraftPoint } from 'ushin-db'

import './point.scss'
import './button.scss'

import { AllShapes } from './AllShapes'
import { useTextareaIndent } from '../hooks/useTextareaIndent'

interface Props {
  displayPoint: DraftPoint | PointI
  // TODO: make not optional
  buttons?: React.ReactNode
  expandedRegion?: string | null
  showOptions?: boolean
  readOnly: boolean
  suppressAutoFocus?: boolean
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleKeyDown?: (e: React.KeyboardEvent) => void
  handleBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  handleShapeIconClick?: (e: React.MouseEvent) => void
  shapeButtonTitle?: string
}

// TODO: fix ref type below
export const DisplayPoint = forwardRef<any, Props>(({
  displayPoint,
  buttons,
  expandedRegion,
  showOptions,
  readOnly,
  suppressAutoFocus,
  handleChange,
  handleKeyDown,
  handleBlur,
  handleShapeIconClick,
  shapeButtonTitle
}, ref) => {
  const divRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const buttonGroupRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    get div () {
      return divRef.current
    },
    get button () {
      return buttonRef.current
    },
    get textarea () {
      return textareaRef.current
    }
  }))

  const { textareaIndent, textareaNewline } = useTextareaIndent(divRef, buttonGroupRef)

  // The useState and useEffect are purely to cause the component to
  // re-render after it first mounts. A better solution must exist.
  const [, setCounter] = useState(0)
  useEffect(() => {
    setCounter((c) => c + 1)
  }, [expandedRegion, showOptions])

  let pointTextareaClassName = 'point-textarea'
  if (textareaNewline) pointTextareaClassName += ' newline'

  let allShapesButtonClassName = 'button'
  // If the allShapesButton has no handler, don't show hover styles, since they could be misleading
  if (handleShapeIconClick === undefined) allShapesButtonClassName += ' no-hover'

  return (
    <div
      className='point-wrapper'
      onBlur={handleBlur}
      ref={divRef}
    >
      <div
        className='button-group' ref={buttonGroupRef} onClick={e => {
          // Prevent click event from triggering navigation change in MessageListItem
          // if MessageListItem has passed buttons to DisplayPoint
          buttons !== undefined && e.preventDefault()
        }}
      >
        <button className={allShapesButtonClassName} onClick={handleShapeIconClick} title={shapeButtonTitle} ref={buttonRef}>
          <AllShapes shape={displayPoint.shape} />
        </button>
        {buttons}
      </div>
      <TextareaAutosize
        className={pointTextareaClassName}
        style={{ textIndent: textareaIndent }}
        value={displayPoint.content}
        onChange={handleChange}
        readOnly={readOnly}
        ref={textareaRef}
        autoFocus={suppressAutoFocus === undefined || !suppressAutoFocus}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
})
