/*
  Copyright (C) 2020-22 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { draftMessageCreate, movePointsFromMessage, quotePointsFromMessage, replyToPoint } from './drafts'

export const Modals = {
  closed: 'CLOSED',
  settingsModal: 'SETTINGS_MODAL',
  selectIdentityModal: 'SELECT_IDENTITY_MODAL',
  createIdentityModal: 'CREATE_IDENTITY_MODAL',
  editIdentityModal: 'EDIT_IDENTITY_MODAL',
  draftsModal: 'DRAFTS_MODAL',
  movePointsDraftsModal: 'MOVE_POINTS_DRAFTS_MODAL',
  replyToPointDraftsModal: 'REPLY_TO_POINT_DRAFTS_MODAL'
}

interface ModalState {
  modal: string
}

const initialModalState: ModalState = { modal: Modals.closed }

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    openSettingsModal: (state) => ({ modal: Modals.settingsModal }),
    openCreateIdentityModal: (state) => ({ modal: Modals.createIdentityModal }),
    openEditIdentityModal: (state) => ({ modal: Modals.editIdentityModal }),
    openSelectIdentityModal: (state) => ({ modal: Modals.selectIdentityModal }),
    openSelectOrCreateIdentityModal: (state) => state, // In currentIdentityExistsMiddleware, we'll first check if any writable identities exist
    openDraftsModal: (state) => ({ modal: Modals.draftsModal }),
    openMovePointsDraftsModal: (state, action: PayloadAction<{
      pointURL: string
    }>) => ({ modal: Modals.movePointsDraftsModal }),
    openReplyToPointDraftsModal: (state, action: PayloadAction<{
      pointURL: string
    }>) => ({ modal: Modals.replyToPointDraftsModal }),
    closeModal: (state) => ({ modal: Modals.closed })
  },
  extraReducers: builder => builder
    .addMatcher(isAnyOf(draftMessageCreate, movePointsFromMessage, quotePointsFromMessage, replyToPoint), (state) => ({ modal: Modals.closed }))
})

export const {
  openSettingsModal,
  openCreateIdentityModal,
  openEditIdentityModal,
  openSelectIdentityModal,
  openSelectOrCreateIdentityModal,
  openDraftsModal,
  openMovePointsDraftsModal,
  openReplyToPointDraftsModal,
  closeModal
} = modalSlice.actions
export default modalSlice.reducer
