/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { forwardRef, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { PinFill } from 'react-bootstrap-icons'

import './message-list-item.scss'
import './button.scss'

import {
  getPointByURL,
  extractAuthorURL,
  isDraft,
  isPublishedPoint,
  isQuote,
  removeHyperPrefix
} from '../dataModels/pointUtils'
import { DisplayPoint } from './DisplayPoint'
import { Banner } from './Banner'
import { TimeStamp } from './TimeStamp'

import { useAppSelector } from '../hooks/useRedux'

interface Props {
  messageURL: string
  mainPointURL: string
  mainPointButtons?: React.ReactNode
  subPointURLs?: string[]
  showOptions: boolean
  isSelected: boolean
  handleMessageListTopBarClick: (e: React.MouseEvent) => void
  handleMainPointShapeIconClick?: (e: React.MouseEvent) => void
  mainPointShapeButtonTitle?: string
  handleMainPointBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  handleClick?: (messageURL: string) => void
  handleChildMouseOver?: () => void
  handleChildMouseOut?: () => void
}

export const MessageListItem = forwardRef<any, Props>(({
  messageURL,
  mainPointURL,
  mainPointButtons,
  subPointURLs,
  showOptions,
  isSelected,
  handleMessageListTopBarClick,
  handleMainPointShapeIconClick,
  mainPointShapeButtonTitle,
  handleMainPointBlur,
  handleClick,
  handleChildMouseOver,
  handleChildMouseOut
}, pointRef): ReactElement => {
  const authorURL = extractAuthorURL(messageURL)
  const borderColor = useAppSelector(({ authors }) => {
    if (authorURL !== undefined) return authors.byURL[authorURL].color
  })

  const mainPoint = useAppSelector(({ published, drafts }) => getPointByURL(mainPointURL, published, drafts))

  const subPoints = useAppSelector(({ published, drafts }) => {
    return subPointURLs?.map(url => getPointByURL(url, published, drafts))
  })

  const isPinned = useAppSelector(({ authors, pinnedMessages }) => {
    const { currentIdentity } = authors
    return currentIdentity !== undefined && pinnedMessages[currentIdentity] !== undefined && pinnedMessages[currentIdentity].includes(messageURL)
  })

  // Get createdAt from point, not message, because we might not have the message for a published message, but we certainly have the point if we can display it
  const createdAt = isPublishedPoint(mainPoint) ? mainPoint.createdAt : undefined

  function handleLinkClick (e: React.MouseEvent): void {
    if (handleClick !== undefined) {
      e.preventDefault()
      handleClick(messageURL)
    }
  }

  // We don't want to display the hover styles on a MessageListItem when one of its child Link elements is hovered.
  // HACK: Because CSS selectors can't know whether a child element is selected, we have to use Javascript to inform the parent MessageListItem that a child MessageListItem or Banner is hovered
  const [childIsHovered, setChildIsHovered] = useState(false)

  function handleMouseOver (): void {
    setChildIsHovered(true)
  }

  function handleMouseOut (): void {
    setChildIsHovered(false)
  }

  function mapPointToElement (point: Point | QuotePoint | DraftPoint | DraftQuotePoint): React.ReactNode {
    if (isQuote(point)) {
      const { messageURL, pointURL } = point.quote
      return (
        <MessageListItem
          messageURL={messageURL}
          mainPointURL={pointURL}
          handleChildMouseOver={handleMouseOver}
          handleChildMouseOut={handleMouseOut}
          parentSetChildIsHovered={setChildIsHovered}
          key={point.url}
        />
      )
    } else {
      return (
        <DisplayPoint
          displayPoint={point}
          buttons={mainPointButtons}
          handleShapeIconClick={handleMainPointShapeIconClick}
          shapeButtonTitle={mainPointShapeButtonTitle}
          handleBlur={handleMainPointBlur}
          readOnly
          suppressAutoFocus
          showOptions={showOptions}
          ref={pointRef}
          key={point.url}
        />
      )
    }
  }

  let messageListItemLinkClassName = 'message-list-item-link'
  if (isDraft(messageURL)) messageListItemLinkClassName += ' draft'
  if (isSelected) messageListItemLinkClassName += ' selected'
  if (childIsHovered !== undefined && childIsHovered) messageListItemLinkClassName += ' no-hover'

  return (
    <div className='message-list-item'>
      <div className='message-list-item-top-bar' onClick={handleMessageListTopBarClick}>
        <div className='button-group'>
          {isPinned && <PinFill />}
          <TimeStamp createdAt={createdAt} />
        </div>
        {authorURL !== undefined && <Banner
          authorURL={authorURL}
          handleMouseOver={handleChildMouseOver}
          handleMouseOut={handleChildMouseOut}
                                    />}
      </div>
      <Link
        className={messageListItemLinkClassName}
        to={`/${removeHyperPrefix(messageURL)}`}
        style={{ borderColor }}
        onClick={handleLinkClick}
        onMouseOver={handleChildMouseOver}
        onMouseOut={handleChildMouseOut}
      >
        {mapPointToElement(mainPoint)}
        {subPoints !== undefined && (
          <div className='sub-points'>
            {subPoints.map(mapPointToElement)}
          </div>
        )}
      </Link>
    </div>
  )
})
