/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'

import './search-panel.scss'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { continueSearchMessages } from '../slices/search'

import { SearchForm } from './SearchForm'
import { MessageListItem } from './MessageListItem'

const BOTTOM_OF_DIV_THRESHOLD = 25

export const SearchPanel = (): ReactElement => {
  const dispatch = useAppDispatch()

  const results = useAppSelector(({ search, published }) => {
    return search.results.map(messageURL => {
      const message = published.messages[messageURL]
      return { messageURL, mainPointURL: message.main }
    })
  })

  const hasSearched = useAppSelector(({ search }) => search.hasSearched)
  const continueSearchMessagesPossible = useAppSelector(({ search }) => search.continueSearchMessagesPossible)
  const searchResultsDone = useAppSelector(({ search }) => search.searchResultsDone)

  function handleResultsScroll (e: React.ScrollEvent<HTMLDivElement>): void {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    if (!searchResultsDone && scrollHeight - scrollTop - BOTTOM_OF_DIV_THRESHOLD < clientHeight) {
      dispatch(continueSearchMessages())
    }
  }

  function handleSearchResultsDoneButtonClick (): void {
    dispatch(continueSearchMessages())
  }

  let belowResultsContent = null
  if (continueSearchMessagesPossible) {
    // This button is necessary in case the screen height is big enough to fit 20 messages without overflowing (in which case the scroll handler would never trigger)
    belowResultsContent = <button onClick={handleSearchResultsDoneButtonClick}>More...</button>
  } else if (searchResultsDone) {
    belowResultsContent = <div>That's it! New search?</div>
  } else if (hasSearched) {
    belowResultsContent = <div>Loading...</div>
  }

  return (
    <div className='search-panel'>
      <SearchForm />
      <div className='results'>
        <div className='results-scrollable' onScroll={handleResultsScroll}>
          {results.map(({ messageURL, mainPointURL }) => (
            <MessageListItem messageURL={messageURL} mainPointURL={mainPointURL} key={messageURL} />
          ))}
          {belowResultsContent}
        </div>
      </div>
    </div>
  )
}
