/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { useMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { closeModal } from '../slices/modal'
import { isDraft, makeMessageURL } from '../dataModels/pointUtils'

import { ModalContainer } from './ModalContainer'
import { DraftMessages } from './DraftMessages'

export const MovePointsDraftsModal = (): ReactElement => {
  const dispatch = useAppDispatch()

  const match = useMatch(':authorId/messages/docs/:messageId')
  let currentMessageURL
  if (match !== null) {
    const { authorId, messageId } = match.params
    currentMessageURL = makeMessageURL(authorId, messageId)
  }

  const selectedPointsLength = useAppSelector(({ selectedPoints }) => selectedPoints.pointURLs.length)

  let modalTitle = ''
  if (isDraft(currentMessageURL)) {
    modalTitle = `Move ${selectedPointsLength} points to`
  } else {
    modalTitle = `Quote ${selectedPointsLength} points in`
  }

  return (
    <ModalContainer
      title={modalTitle}
      width='300px'
      closeModal={() => dispatch(closeModal())}
    >
      <DraftMessages action='movePoints' />
    </ModalContainer>
  )
}
