/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { useMatch } from 'react-router-dom'

import './profile-page.scss'

import { makeAuthorURL } from '../dataModels/pointUtils'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { getAuthorInfo } from '../slices/authors'

import { PageContainer } from './PageContainer'
import { ProfilePageTopBar } from './ProfilePageTopBar'
import { ProfilePageHeader } from './ProfilePageHeader'
import { Collapsible } from './Collapsible'
import { PeerList } from './PeerList'
import { PinnedMessages } from './PinnedMessages'
import { LoadingPage } from './LoadingPage'

export const ProfilePage = (): ReactElement => {
  const dispatch = useAppDispatch()

  const { authorId } = useMatch(':authorId').params
  const authorURL = makeAuthorURL(authorId)
  const author = useAppSelector(({ authors }) => authors.byURL[authorURL])

  if (author === undefined) {
    dispatch(getAuthorInfo({ authorURLs: [authorURL] }))
    return <LoadingPage>Loading peer...</LoadingPage>
  }

  // TODO: Timeout if the author cannot be found?
  return (
    <PageContainer topBarContents={<ProfilePageTopBar authorURL={authorURL} />}>
      <div
        className='profile-page'
        style={{ borderColor: author.color }}
      >
        <ProfilePageHeader authorURL={authorURL} />
        <Collapsible header='Peers' startOpen content={<PeerList authorURL={authorURL} />} />
        <Collapsible header='Pinned Messages' startOpen content={<PinnedMessages authorURL={authorURL} />} />
      </div>
    </PageContainer>
  )
}
