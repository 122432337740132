/*
  Copyright (C) 2020-21 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { X } from 'react-bootstrap-icons'

import './modal-container.scss'
import './button.scss'

interface Props {
  title?: React.ReactNode
  width?: string
  onOk?: () => void
  closeModal: () => void
  children: React.ReactNode
}

export const ModalContainer = ({
  title = '',
  width = '600px',
  onOk,
  closeModal,
  children
}: Props): ReactElement => {
  const handleOverlayClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
    closeModal()
  }

  const handleModalClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }

  const handleCloseButtonClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
    closeModal()
  }

  const handleOkButtonClick = (e: React.MouseEvent): void => {
    e.stopPropagation()
    onOk?.()
  }

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
      <div className='modal-container' onClick={handleModalClick} style={{ width }}>
        <div className='modal-title'>{title}</div>
        <div className='button-group modal-header'>
          <button className='button' onClick={handleCloseButtonClick} title='Close popup'>
            <X className='bi' />
          </button>
        </div>
        <div className='modal-content-outer-container'>
          <div className='modal-content-inner-container'>
            {children}
          </div>
        </div>
        {onOk !== undefined && (
          <div className='button-group modal-footer'>
            <button className='button' onClick={handleOkButtonClick} title='Close popup'>
              OK
            </button>
          </div>)}
      </div>
    </div>
  )
}
