export function deepEquals (a: any, b: any): boolean {
  if (a === b) return true
  if (typeof a !== 'object' || typeof b !== 'object' || a == null || b == null) return false
  const keysA = Object.keys(a); const keysB = Object.keys(b)
  if (keysA.length !== keysB.length) return false
  for (const key of keysA) {
    if (!keysB.includes(key)) return false
    if (typeof a[key] === 'function' || typeof b[key] === 'function') {
      if (a[key].toString() !== b[key].toString()) return false
    } else {
      if (!deepEquals(a[key], b[key])) return false
    }
  }
  return true
}

export function isInt (value: any): boolean {
  return !isNaN(value) &&
    parseInt(Number(value)) === value &&
    !isNaN(parseInt(value, 10))
}
