/*
Copyright (C) 2020-22 by USHIN, Inc.

This file is part of U4U.

U4U is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

U4U is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import {
  movePointsFromMessage,
  quotePointsFromMessage,
  deletePoints,
  autoDeleteEmptyPoint,
  combinePoints
} from '../slices/drafts'
import { beginDrag } from '../slices/drag'
import { openMovePointsDraftsModal, openReplyToPointDraftsModal } from './modal'
import { undoDraft, redoDraft } from './draft'

interface SelectedPointsState {
  pointURLs: string[]
  // `preserve` is used to prevent selectedPointsState from being cleared
  // when location pathname changes
  preserve: boolean
}

const initialSelectedPointsState: SelectedPointsState = {
  pointURLs: [],
  preserve: false
}

const selectedPointsSlice = createSlice({
  name: 'selectedPoints',
  initialState: initialSelectedPointsState,
  reducers: {
    togglePoint: (state, action: PayloadAction<{
      pointURL: string
    }>) => {
      const newPointURLs = state.pointURLs.filter(
        (pointURL) => pointURL !== action.payload.pointURL
      )
      if (newPointURLs.length === state.pointURLs.length) {
        newPointURLs.push(action.payload.pointURL)
      }

      state.pointURLs = newPointURLs
    },

    clearSelectedPoints: (state) => {
      if (!state.preserve) state.pointURLs = []
      state.preserve = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(combinePoints, (state, action) => {
        state.pointURLs = state.pointURLs.filter(
          (url) => url !== action.payload.pointToDelete.url
        )
      })
      .addCase(openReplyToPointDraftsModal, (state, action) => ({ pointURLs: [action.payload.pointURL], preserve: false }))
      .addMatcher(isAnyOf(beginDrag, openMovePointsDraftsModal), (state, action) => {
        const { pointURL } = action.payload
        if (!state.pointURLs.includes(pointURL)) {
          state.pointURLs.push(pointURL)
        }
      })
      .addMatcher(isAnyOf(movePointsFromMessage, quotePointsFromMessage), (state, action) => {
        const { newPoints } = action.payload
        state.pointURLs = newPoints.map((p) => p.url)
        state.preserve = true
      })
      .addMatcher(isAnyOf(deletePoints, autoDeleteEmptyPoint), (state, action) => {
        state.pointURLs = state.pointURLs.filter(
          (url) => !action.payload.pointURLs.includes(url)
        )
      })
      .addMatcher(isAnyOf(undoDraft, redoDraft), (state) => ({ pointURLs: [], preserve: false }))
  }
})

export const {
  togglePoint,
  clearSelectedPoints
} = selectedPointsSlice.actions
export default selectedPointsSlice.reducer
