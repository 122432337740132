/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { helpers, TrustArea, TrustToLoad } from 'ushin-db'
import { SetURLSearchParams } from 'react-router-dom'

import { initAppSuccess } from './initApp'

import { DEFAULT_MAX_HOPS, DEFAULT_THRESHOLD } from '../utils/trustHelpers'

export interface AuthorTrustParams {
  [authorURL: string]: {
    maxHops: {
      [helpers.SOURCE]: number
      [helpers.BLOCKER]: number
    }
    threshold: {
      [helpers.SOURCE]: number
      [helpers.BLOCKER]: number
    }
  }
}

export interface ThresholdOrMaxHops {
  [helpers.SOURCE]: number
  [helpers.BLOCKER]: number
}

interface TrustState {
  [authorURL: string]: TrustToLoad & {
    maxHops: ThresholdOrMaxHops
    threshold: ThresholdOrMaxHops
  }
  pendingSyncSearchParams: boolean
}

const initialTrustState: TrustState = {
  pendingSyncSearchParams: false
}

const trustSlice = createSlice({
  name: 'trust',
  initialState: initialTrustState,
  reducers: {
    getTrustInfo: (state, action: PayloadAction<{
      authorURLs: string[]
    }>) => state,

    getTrustInfoSuccess: (state, action: PayloadAction<{
      trustToLoad: TrustToLoad
    }>) => {
      addTrustInfo(state, action.payload.trustToLoad)
    },

    setTrustWeight: (state, action: PayloadAction<{
      fromAuthorURL: string
      toAuthorURL: string
      trustArea: TrustArea
      weight: number
    }>) => state,

    unsetTrustWeight: (state, action: PayloadAction<{
      fromAuthorURL: string
      toAuthorURL: string
      trustArea: TrustArea
    }>) => state,

    addDistrusted: (state, action: PayloadAction<{
      fromAuthorURL: string
      toAuthorURL: string
      trustArea: TrustArea
    }>) => state,

    setThreshold: (state, action: PayloadAction<{
      authorURL: string
      trustArea: TrustArea
      threshold: number
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
    }>) => state,

    setThresholdSuccess: (state, action: PayloadAction<{
      authorURL: string
      trustArea: TrustArea
      threshold: number
      trustToLoad: TrustToLoad
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
    }>) => {
      const { authorURL, trustArea, threshold, trustToLoad } = action.payload
      state[authorURL].threshold[trustArea] = threshold
      addTrustInfo(state, trustToLoad)
    },

    setMaxHops: (state, action: PayloadAction<{
      authorURL: string
      trustArea: TrustArea
      maxHops: number
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
    }>) => state,

    setMaxHopsSuccess: (state, action: PayloadAction<{
      authorURL: string
      trustArea: TrustArea
      maxHops: number
      trustToLoad: TrustToLoad
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
    }>) => {
      const { authorURL, trustArea, maxHops, trustToLoad } = action.payload
      state[authorURL].maxHops[trustArea] = maxHops
      addTrustInfo(state, trustToLoad)
    },

    syncTrustParamsAndSearchParams: (state, action: PayloadAction<{
      authorURL: string
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
    }>) => {
      state.pendingSyncSearchParams = true
    },

    syncTrustParamsAndSearchParamsSuccess: (state, action: PayloadAction<{
      authorURL: string
      searchParams: URLSearchParams
      setSearchParams: SetURLSearchParams
      authorTrustParams: AuthorTrustParams
      trustToLoad: TrustToLoad
    }>) => {
      const { authorURL, authorTrustParams, trustToLoad } = action.payload
      state[authorURL] = { ...state[authorURL], ...authorTrustParams[authorURL] }
      addTrustInfo(state, trustToLoad)
      state.pendingSyncSearchParams = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(initAppSuccess, (state, action) => {
        addTrustInfo(state, action.payload.trustToLoad)
      })
  }
})

function addTrustInfo (state: TrustState, trustToLoad: TrustToLoad): void {
  for (const url in trustToLoad) {
    state[url] = {
      threshold: state[url]?.threshold ?? DEFAULT_THRESHOLD,
      maxHops: state[url]?.maxHops ?? DEFAULT_MAX_HOPS,
      ...trustToLoad[url]
    }
  }
}

export const {
  getTrustInfo,
  getTrustInfoSuccess,
  setTrustWeight,
  unsetTrustWeight,
  addDistrusted,
  setThreshold,
  setThresholdSuccess,
  setMaxHops,
  setMaxHopsSuccess,
  syncTrustParamsAndSearchParams,
  syncTrustParamsAndSearchParamsSuccess
} = trustSlice.actions
export default trustSlice.reducer
