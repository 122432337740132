/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { helpers } from 'ushin-db'

import './peer-graph.scss'

import { makeAuthorURL } from '../dataModels/pointUtils'
import { getTrustParamsFromSearchParams } from '../utils/trustHelpers'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { getAuthorInfo } from '../slices/authors'
import { getTrustInfo, syncTrustParamsAndSearchParams } from '../slices/trust'

import { PageContainer } from './PageContainer'
import { TopBarContainer } from './TopBarContainer'
import { PeerGraph } from './PeerGraph'
import { Banner } from './Banner'
import { LoadingPage } from './LoadingPage'

export const PeerGraphPage = (): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const { authorId } = useParams()
  // TODO: upgrade react-router to v6 to get params without having to pad with '/'
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const authorURL = authorId !== undefined ? makeAuthorURL(authorId) : undefined

  const author = useAppSelector(({ authors }) => {
    if (authorURL !== undefined) return authors.byURL[authorURL]
  })

  let allAuthorURLs = useAppSelector(({ trust }) => {
    if (authorURL !== undefined && trust[authorURL] !== undefined) {
      const { sourceURLHopsMapping, blockerURLHopsMapping, blockedButDirectSourceURLHopsMapping, blockedURLs } = trust[authorURL]
      return [
        ...Object.keys(sourceURLHopsMapping),
        ...Object.keys(blockerURLHopsMapping),
        ...Object.keys(blockedButDirectSourceURLHopsMapping),
        // No need to check indirectSourceButBlockedURLHopsMapping because they are included in blockedURLs
        ...blockedURLs
      ]
    }
  })

  // Dedupe toAuthorURLs, since the query string could have more than one of the same topeer authorURL
  const toAuthorURLs = [...new Set(searchParams.getAll('topeer').map(id => makeAuthorURL(id)))]

  if (toAuthorURLs.length > 0 && allAuthorURLs !== undefined) {
    allAuthorURLs = [...new Set([...allAuthorURLs, ...toAuthorURLs])]
  }

  const isMissingAuthorInfo = useAppSelector(({ authors, trust }) => {
    return allAuthorURLs === undefined ||
           allAuthorURLs.some(url => authors.byURL[url] === undefined)
  })

  const isMissingTrustInfo = useAppSelector(({ authors, trust }) => {
    return allAuthorURLs === undefined ||
           allAuthorURLs.some(url => trust[url] === undefined)
  })

  useEffect(() => {
    dispatch(syncTrustParamsAndSearchParams({ authorURL, searchParams, setSearchParams }))
  }, [dispatch, authorURL, searchParams, setSearchParams])

  const peerGraphLoadingPage = (
    <LoadingPage>
      {author === undefined
        ? <div>Loading peer graph</div>
        : <div>Loading <Banner authorURL={authorURL} />'s peer graph</div>}
    </LoadingPage>
  )

  if (author === undefined) {
    dispatch(getAuthorInfo({ authorURLs: [authorURL] }))
    return peerGraphLoadingPage
  }

  if (allAuthorURLs === undefined) {
    dispatch(getTrustInfo({ authorURLs: [authorURL] }))
    return peerGraphLoadingPage
  }

  if (isMissingAuthorInfo) {
    dispatch(getAuthorInfo({ authorURLs: allAuthorURLs }))
    return peerGraphLoadingPage
  }

  if (isMissingTrustInfo) {
    dispatch(getTrustInfo({ authorURLs: allAuthorURLs }))
    return peerGraphLoadingPage
  }

  const topBarContents = (
    <TopBarContainer leftButtons={
      <div>
        <Banner authorURL={authorURL} />'s peer graph
      </div>
    }
    />
  )

  const { threshold, maxHops } = getTrustParamsFromSearchParams(searchParams)

  // TODO: Timeout if the author cannot be found?
  return (
    <PageContainer topBarContents={topBarContents}>
      <div
        className='peer-graph-page'
        style={{ borderColor: author.color }}
      >
        <PeerGraph
          authorURL={authorURL}
          toAuthorURLs={toAuthorURLs}
          sourceThreshold={threshold[helpers.SOURCE]}
          sourceMaxHops={maxHops[helpers.SOURCE]}
          blockerThreshold={threshold[helpers.BLOCKER]}
          blockerMaxHops={maxHops[helpers.BLOCKER]}
          showSources={searchParams.has('source')}
          showBlockers={searchParams.has('blocker')}
          showBlocked={searchParams.has('blocked')}
          showAllBlocked={searchParams.has('allblocked')}
          showShortestPath={searchParams.has('shortestpath')}
          showFunneled={searchParams.has('funnel')}
        />
      </div>
    </PageContainer>
  )
}
