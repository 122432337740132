/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ReplyFill, ArrowsMove, XLg, ChatSquareQuote, ExclamationLg } from 'react-bootstrap-icons'

import './point.scss'
import './button.scss'

import { getPointByURL, isDraft, isQuote } from '../dataModels/pointUtils'
import { ItemTypes, DraggablePointType } from '../constants/React-Dnd'
import { DisplayPoint } from './DisplayPoint'
import { MessageListItem } from './MessageListItem'

import { useDrop, DropTargetMonitor } from 'react-dnd'
import { useDragPoint } from '../hooks/useDragPoint'
import { XYCoord } from 'dnd-core'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'

import { jumpToPrevPointThunk, jumpToNextPointThunk, clearCursorPosition } from '../slices/cursorPosition'
import {
  draftPointCreate,
  splitIntoTwoPoints,
  combinePointsThunk,
  pointsMoveWithinMessageThunk,
  draftPointUpdate,
  deletePointsThunk,
  autoDeleteEmptyPoint,
  setMainThunk
} from '../slices/drafts'
import { hoverOver } from '../slices/drag'
import { togglePoint } from '../slices/selectedPoints'
import { openMovePointsDraftsModal, openReplyToPointDraftsModal } from '../slices/modal'
import { setExpandedRegion, toggleExpandedRegion } from '../slices/expandedRegion'

interface Props {
  messageURL: string
  pointURL: string
  region: string
  index: number
}

export const Point = ({ messageURL, pointURL, region, index }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const point = useAppSelector(({ published, drafts }) => getPointByURL(pointURL, published, drafts))

  // TODO: why does RegionPoint need to know expandedRegion?
  const expandedRegion = useAppSelector(({ expandedRegion }) => expandedRegion.region)

  const cursorPositionIndex = useAppSelector(({ cursorPosition }) => {
    if (cursorPosition.details != null &&
        cursorPosition.details.pointURL === pointURL) {
      return cursorPosition.details.contentIndex
    }
  })

  const isSelected = useAppSelector(({ selectedPoints }) => selectedPoints.pointURLs.includes(pointURL))

  const [, drop] = useDrop({
    accept: ItemTypes.POINT,
    hover: (item: DraggablePointType, monitor: DropTargetMonitor) => {
      const hoverIndex = index
      const dragIndex = item.index

      const hoverBoundingRect = displayPointRef.current?.div.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      let newIndex = hoverIndex

      if (dragIndex === hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      if (dragIndex === hoverIndex && hoverClientY > hoverMiddleY) newIndex++

      item.index = newIndex
      item.region = region

      dispatch(hoverOver({ region, index: newIndex }))
    },
    drop: () => {
      if (isDraft(pointURL)) {
        dispatch(pointsMoveWithinMessageThunk({ messageURL }))
      }
    }
  })

  const { drag, preview } = useDragPoint(pointURL, index)

  // TODO: fix ref type
  const displayPointRef = useRef<any>(null)

  drag(displayPointRef.current?.button)

  const pointPreview = preview(displayPointRef.current?.div)
  if (region !== 'center') drop(pointPreview)

  const handleMessageListTopBarClick = (e: React.MouseEvent): void => {
    dispatch(toggleExpandedRegion({ region }))
  }

  const handleShapeIconClick = (e: React.MouseEvent): void => {
    setShowOptions(true)
    dispatch(setExpandedRegion({ region }))
    dispatch(togglePoint({ pointURL }))
  }

  const handlePointClick = (e: React.MouseEvent): void => {
    dispatch(setExpandedRegion({ region }))
    e.stopPropagation() // Prevent the event from reaching the CenterRegion handler which toggles the expanded region
  }

  const [content, setContent] = useState(point.content)

  // HACK: for moving cursor position after cursorPosition actions
  useEffect(() => {
    if (typeof cursorPositionIndex !== 'number') return
    displayPointRef.current?.textarea.focus()
    displayPointRef.current?.textarea.setSelectionRange(cursorPositionIndex, cursorPositionIndex)

    // combinePoints action results in setContent being called
    // When the point re-renders as a result of useState hook change, the selectionRange is reset.
    // So... keep trying to setSelectionRange until point has rendered with the new point content, for which this conditional check is a proxy.
    // TODO: clearCursorPosition never registers after jumpToPrevPointThunk action (because cursorPositionIndex !== content.length)
    // As a consequence, when splitting a point after jumpToPrevPointThunk, the cursor jumps back to the end of the prior point
    if (cursorPositionIndex !== content.length) dispatch(clearCursorPosition())
  })

  useEffect(() => {
    setContent(point.content)
  }, [point])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContent(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (!isDraft(pointURL) || isQuote(point)) return

    if (region === 'center') {
      if (e.key === 'Enter') e.preventDefault()
      return
    }

    const textareaRef = displayPointRef.current.textarea
    const isAtBeginning = textareaRef.selectionStart === 0
    const isAtEnd = textareaRef.selectionEnd === textareaRef.value.length
    const textIsSelected = textareaRef.selectionStart !== textareaRef.selectionEnd
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isAtBeginning || textIsSelected) return
      dispatch(draftPointUpdate({ messageURL, point: { ...point, content } }))
      if (isAtEnd) {
        dispatch(draftPointCreate({ shape: region, index: index + 1, messageURL, main: false }))
      } else {
        // Call setContent before splitIntoTwoPoints so that draftPointUpdate does not overwrite the new content with the previous content
        setContent(textareaRef.value.slice(0, textareaRef.selectionStart))
        dispatch(splitIntoTwoPoints({ pointURL, sliceIndex: textareaRef.selectionStart, messageURL }))
      }
    } else if (e.key === 'Backspace' && isAtBeginning && !textIsSelected) {
      if (index !== 0) {
        e.preventDefault()
        if (content === '') {
          dispatch(jumpToPrevPointThunk({ messageURL, shape: region, index }))
        } else {
          dispatch(draftPointUpdate({ messageURL, point: { ...point, content } }))
          dispatch(combinePointsThunk({ shape: region, messageURL, keepIndex: index - 1, deleteIndex: index }))
        }
      } else if (index === 0 && content === '') {
        e.preventDefault()
        dispatch(jumpToNextPointThunk({ messageURL, shape: region, index }))
      }
    } else if (e.key === 'Delete' && isAtEnd && !textIsSelected) {
      e.preventDefault()
      if (content === '') {
        dispatch(jumpToNextPointThunk({ messageURL, shape: region, index }))
      } else {
        dispatch(draftPointUpdate({ messageURL, point: { ...point, content } }))
        dispatch(combinePointsThunk({ shape: region, messageURL, keepIndex: index, deleteIndex: index + 1 }))
      }
    } else if (e.key === 'ArrowLeft' && isAtBeginning && !textIsSelected) {
      e.preventDefault()
      dispatch(jumpToPrevPointThunk({ messageURL, shape: region, index }))
    } else if (e.key === 'ArrowRight' && isAtEnd && !textIsSelected) {
      e.preventDefault()
      dispatch(jumpToNextPointThunk({ messageURL, shape: region, index }))
    }
  }

  const messageListItemWrapperRef = useRef<HTMLDivElement>(null)

  function handleBlur (e: React.FocusEvent<HTMLDivElement>): void {
    let focusWentAwayFromPoint = !e.currentTarget.contains(e.relatedTarget as Node)
    if (messageListItemWrapperRef.current !== null) {
      focusWentAwayFromPoint = !messageListItemWrapperRef.current.contains(e.relatedTarget as Node)
    }

    if (focusWentAwayFromPoint) setShowOptions(false)

    if (!isDraft(pointURL) || isQuote(point)) return
    if (content === '') {
      dispatch(autoDeleteEmptyPoint({ messageURL, pointURLs: [pointURL] }))
    } else {
      dispatch(draftPointUpdate({ messageURL, point: { ...point, content } }))
    }
  }

  function handleSetMainPointButtonClick (e: React.MouseEvent): void {
    dispatch(setMainThunk({ newMainURL: pointURL, messageURL }))
  }

  function handleReplyButtonClick (e: React.MouseEvent): void {
    dispatch(openReplyToPointDraftsModal({ pointURL }))
  }

  function handleXButtonClick (e: React.MouseEvent): void {
    dispatch(deletePointsThunk({ pointURLs: [pointURL], messageURL }))
  }

  function handleMoveOrQuotePointsClick (e: React.MouseEvent): void {
    dispatch(openMovePointsDraftsModal({ pointURL }))
  }

  // Hide or show point options
  const [showOptions, setShowOptions] = useState(false)

  // HACK: if `buttons` is false, then the allShapesIcon will light up when hovered. If `buttons` is undefined (i.e. when MessageListItem is a child of DraftMessages), then the allShapesIcon will not light up when hovered.
  let buttons: React.ReactNode = false

  if (showOptions) {
    if (isDraft(pointURL)) {
      buttons = (
        <>
          {(region !== 'center') && (
            <button className='button' onClick={handleSetMainPointButtonClick} title='Set main point'>
              <ExclamationLg className='bi' />
            </button>
          )}
          <button className='button' onClick={handleMoveOrQuotePointsClick} title='Move point(s)'>
            <ArrowsMove className='bi' />
          </button>
          <button className='button red' onClick={handleXButtonClick} title='Delete point(s)'>
            <XLg className='bi' />
          </button>
        </>
      )
    } else {
      buttons = (
        <>
          <button className='button' onClick={handleReplyButtonClick} title='Reply to this point'>
            <ReplyFill className='bi' />
          </button>
          <button className='button' onClick={handleMoveOrQuotePointsClick} title='Quote point(s)'>
            <ChatSquareQuote className='bi' />
          </button>
        </>
      )
    }
  }

  const shapeButtonTitle = 'Select point'

  let pointClassName = 'point'
  if (isSelected) pointClassName += ' selected'
  if (region === 'center') pointClassName += ' main'

  if (isQuote(point)) {
    const { messageURL, pointURL } = point.quote

    // pointURL is not necessarily the main point, but we are displaying it as the main point
    return (
      <div ref={messageListItemWrapperRef}>
        <MessageListItem
          messageURL={messageURL}
          mainPointURL={pointURL}
          mainPointButtons={buttons}
          handleMessageListTopBarClick={handleMessageListTopBarClick}
          handleMainPointShapeIconClick={handleShapeIconClick}
          mainPointShapeButtonTitle={shapeButtonTitle}
          handleMainPointBlur={handleBlur}
          showOptions={showOptions}
          isSelected={isSelected}
          ref={displayPointRef}
        />
      </div>
    )
  }

  return (
    <div className={pointClassName} onClick={handlePointClick}>
      <DisplayPoint
        displayPoint={{ ...point, content }}
        buttons={buttons}
        expandedRegion={expandedRegion}
        showOptions={showOptions}
        readOnly={!isDraft(pointURL)}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        handleBlur={handleBlur}
        handleShapeIconClick={handleShapeIconClick}
        shapeButtonTitle={shapeButtonTitle}
        ref={displayPointRef}
      />
    </div>
  )
}
