/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import './app.scss'

import { useAppSelector, useAppDispatch } from './hooks/useRedux'
import { useLocationChange } from './hooks/useLocationChange'
import { setDrafts } from './slices/drafts'
import { initApp } from './slices/initApp'
import { Landing } from './components/Landing'
import { SemanticScreen } from './components/SemanticScreen'
import { DraftMessagesPage } from './components/DraftMessagesPage'
import { ProfilePage } from './components/ProfilePage'
import { PeerGraphPage } from './components/PeerGraphPage'
import { ModalSwitcher } from './components/ModalSwitcher'

export const App = (): ReactElement | null => {
  const dispatch = useAppDispatch()

  const displayApp = useAppSelector(({ initApp }) => initApp.displayApp)
  const darkMode = useAppSelector(({ settings }) => settings.darkMode)
  const currentIdentityColor = useAppSelector(({ authors }) => (
    authors.currentIdentity !== undefined &&
    authors.byURL[authors.currentIdentity]?.color
  ))

  const cssVariables: React.CSSProperties = {
    // @ts-expect-error
    '--current-identity-color': currentIdentityColor
  }

  useLocationChange()

  const draftsState = useAppSelector(({ drafts }) => drafts)

  useEffect(() => {
    if (displayApp) dispatch(setDrafts({ draftsState }))
  }, [dispatch, displayApp, draftsState])

  if (!displayApp) {
    dispatch(initApp())
    return null
  }

  return (
    <div className='app-wrapper' data-theme={darkMode ? 'dark' : ''} style={cssVariables}>
      <Routes>
        <Route path='drafts' element={<DraftMessagesPage />} />
        <Route path=':authorId/peer-graph' element={<PeerGraphPage />} />
        <Route path=':authorId/messages/docs/:messageId' element={<SemanticScreen />} />
        <Route path=':authorId' element={<ProfilePage />} />
        <Route path='*' element={<Landing />} />
      </Routes>
      <Routes>
        <Route path='*' element={<ModalSwitcher />} />
      </Routes>
    </div>
  )
}
