/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { URLs } from 'ushin-db'
import { X, Check2, Pin, PinFill, ArrowCounterclockwise, ArrowClockwise } from 'react-bootstrap-icons'

import './button.scss'

import { getMessageByURL, isDraft } from '../dataModels/pointUtils'

import { TopBarContainer } from './TopBarContainer'
import { Banner } from './Banner'
import { TimeStamp } from './TimeStamp'

import { draftMessageDelete, undoDraftThunk, redoDraftThunk } from '../slices/drafts'
import { publishMessage } from '../slices/published'
import { pinMessage, unpinMessage } from '../slices/pinnedMessages'

interface Props {
  messageURL: string
}

export const SemanticScreenTopBar = ({ messageURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const isUndoPossible = useAppSelector(({ drafts }) => {
    const draft = drafts.byURL[messageURL]
    return draft !== undefined && draft.past.length > 0
  })
  const isRedoPossible = useAppSelector(({ drafts }) => {
    const draft = drafts.byURL[messageURL]
    return draft !== undefined && draft.future.length > 0
  })

  const createdAt = useAppSelector(({ published, drafts }) => {
    return getMessageByURL(messageURL, published, drafts).createdAt
  })

  const isPinned = useAppSelector(({ authors, pinnedMessages }) => {
    const { currentIdentity } = authors
    return currentIdentity !== undefined && pinnedMessages[currentIdentity] !== undefined && pinnedMessages[currentIdentity].includes(messageURL)
  })

  const { authorURL } = URLs.parseURL(messageURL)
  const navigate = useNavigate()

  function handleXButtonClick (e: React.MouseEvent): void {
    dispatch(draftMessageDelete({ messageURL, navigate }))
    e.stopPropagation()
  }

  function handleUndoButtonClick (e: React.MouseEvent): void {
    dispatch(undoDraftThunk({ messageURL }))
    e.stopPropagation()
  }

  function handleRedoButtonClick (e: React.MouseEvent): void {
    dispatch(redoDraftThunk({ messageURL }))
    e.stopPropagation()
  }

  function handlePublishButtonClick (e: React.MouseEvent): void {
    dispatch(publishMessage({ draftMessageURL: messageURL, navigate }))
    e.stopPropagation()
  }

  function handlePinButtonClick (e: React.MouseEvent): void {
    dispatch(pinMessage({ messageURL }))
    e.stopPropagation()
  }

  function handleUnpinButtonClick (e: React.MouseEvent): void {
    dispatch(unpinMessage({ messageURL }))
    e.stopPropagation()
  }

  let leftButtons: ReactNode = false
  let rightButtons: ReactNode = false
  if (isDraft(messageURL)) {
    leftButtons = (
      <>
        <button className='button red' onClick={handleXButtonClick} title='Delete message'>
          <X className='bi' />
        </button>
        <button className='button' disabled={!isUndoPossible} onClick={handleUndoButtonClick} title='Undo'>
          <ArrowCounterclockwise className='bi' />
        </button>
        <button className='button' disabled={!isRedoPossible} onClick={handleRedoButtonClick} title='Redo'>
          <ArrowClockwise className='bi' />
        </button>
        <button className='button' onClick={handlePublishButtonClick} title='Publish message'>
          <Check2 className='bi' />
        </button>
      </>
    )
  } else {
    leftButtons = (
      <>
        {isPinned
          ? (
            <button className='button' onClick={handleUnpinButtonClick} title='Unpin message'>
              <PinFill className='bi' />
            </button>
            )
          : (
            <button className='button' onClick={handlePinButtonClick} title='Pin message'>
              <Pin className='bi' />
            </button>
            )}
        <TimeStamp createdAt={createdAt} />
      </>
    )
    rightButtons = <Banner authorURL={authorURL} />
  }

  return <TopBarContainer leftButtons={leftButtons} rightButtons={rightButtons} />
}
