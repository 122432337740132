/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { PointShape } from 'ushin-db'

import './region.scss'

import { Point } from './Point'
import { SevenShapes } from './SevenShapes'
import { getMessageByURL, isDraft } from '../dataModels/pointUtils'
import { useDrop } from 'react-dnd'
import { ItemTypes, DraggablePointType } from '../constants/React-Dnd'

import { useAppSelector, useAppDispatch } from '../hooks/useRedux'
import { draftPointCreate, setMainThunk } from '../slices/drafts'
import { toggleExpandedRegion } from '../slices/expandedRegion'
import { hoverOver } from '../slices/drag'

interface Props {
  messageURL: string
}

export const CenterRegion = ({ messageURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const pointURL = useAppSelector(({ published, drafts }) => getMessageByURL(messageURL, published, drafts).main)

  const isExpanded = useAppSelector(({ expandedRegion }) => expandedRegion.region === 'center')

  const [, drop] = useDrop({
    accept: ItemTypes.POINT,
    hover: (item: DraggablePointType) => {
      if (!isExpanded) {
        dispatch(toggleExpandedRegion({ region: 'center' }))
      }
      if (item.index !== 0 || item.region !== 'center') {
        dispatch(hoverOver({
          index: 0,
          region: 'center'
        }))

        item.index = 0
        item.region = 'center'
      }
    },
    drop: () => {
      if (isDraft(messageURL)) dispatch(setMainThunk({ messageURL }))
    }
  })

  const createEmptyMain = (shape: PointShape): void => {
    dispatch(draftPointCreate({
      shape,
      index: 0,
      messageURL,
      main: true
    }))
  }

  let regionClassName = 'region'
  if (isExpanded) regionClassName += ' expanded'

  return (
    <div
      className={regionClassName}
      onClick={() => dispatch(toggleExpandedRegion({ region: 'center' }))}
      ref={drop}
    >
      <div className='region-inner-container'>
        {pointURL !== undefined && (
          <div className='main-point-wrapper'>
            <Point messageURL={messageURL} pointURL={pointURL} region='center' index={0} />
          </div>
        )}
        {pointURL === undefined && isExpanded && (
          <SevenShapes onShapeClick={createEmptyMain} />
        )}
      </div>
    </div>
  )
}
