/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { syncQueryStringMiddleware } from '../middleware/syncQueryStringMiddleware'
import { currentIdentityExistsMiddleware } from '../middleware/currentIdentityExistsMiddleware'
import { createDbMiddleware } from '../middleware/dbMiddleware'
import { routerMiddleware } from '../middleware/routerMiddleware'

import initApp from './initApp'
import cursorPosition from './cursorPosition'
import authors, { AuthorsState } from './authors'
import published from './published'
import drafts from './drafts'
import expandedRegion from './expandedRegion'
import selectedPoints from './selectedPoints'
import panels from './panels'
import drag from './drag'
import search from './search'
import modal from './modal'
import trust from './trust'
import pinnedMessages from './pinnedMessages'
import settings from './settings'

const rootReducer = combineReducers({
  initApp,
  trust,
  pinnedMessages,
  cursorPosition,
  authors,
  published,
  drafts,
  expandedRegion,
  selectedPoints,
  panels,
  drag,
  search,
  modal,
  settings
})

export type RootState = ReturnType<typeof rootReducer>

const preloadedAuthorsState: AuthorsState = {
  byURL: {},
  writableIdentities: {}
}

const currentIdentity = localStorage.getItem('currentIdentity')
if (currentIdentity !== null) {
  preloadedAuthorsState.currentIdentity = currentIdentity
}

const rawWritableIdentities = localStorage.getItem('writableIdentities')
if (rawWritableIdentities !== null) {
  preloadedAuthorsState.writableIdentities = JSON.parse(rawWritableIdentities)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // TODO: add serializableCheck back in after removing state.db
    // Actually, the BSON ids aren't serializable
    serializableCheck: false
  })
    .concat(currentIdentityExistsMiddleware)
    .concat(createDbMiddleware())
    .concat(routerMiddleware)
    .concat(syncQueryStringMiddleware),
  preloadedState: {
    authors: preloadedAuthorsState
  }
})

export type AppDispatch = any
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>
