/*
  Copyright (C) 2020-2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.

  You should have received a copy of the GNU General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import {
  DraftMessage,
  DraftPoint,
  DraftQuotePoint,
  Message,
  Point,
  PointShape,
  QuotePoint,
  URLs
} from 'ushin-db'
import { PublishedState } from '../slices/published'
import { DraftsState } from '../slices/drafts'

export function isQuote (p: Point | DraftPoint | QuotePoint | DraftQuotePoint): p is QuotePoint | DraftQuotePoint {
  return (p as QuotePoint | DraftQuotePoint).quote !== undefined
}

export function isPublishedPoint (p: Point | DraftPoint | QuotePoint | DraftQuotePoint): p is Point | QuotePoint {
  return (p as Point | QuotePoint).createdAt !== undefined
}

export function isDraft (url: string): boolean {
  return URLs.parseURL(url).authorURL === (URLs.HYPER + URLs.DRAFTS + '/')
}

export function extractAuthorURL (url: string): string | undefined {
  if (!isDraft(url)) return URLs.parseURL(url).authorURL
}

export function getPointByURL (pointURL: string, published: PublishedState, drafts: DraftsState): Point | QuotePoint | DraftPoint | DraftQuotePoint {
  if (!isDraft(pointURL)) {
    return published.points[pointURL]
  }
  // This nasty logic for getting draft points by URL is due to the fact that draft points are nested inside drafts messages. We did that to allow for undo/redo functionality, where each message is controlled by its own reducer
  // TODO: consider adding a messageURL attribute to each point so that we don't have iterate like this
  for (const messageURL in drafts.byURL) {
    const point = drafts.byURL[messageURL].present.points[pointURL]
    if (point === undefined) continue
    return point
  }
  throw new Error('point doesn\'t exist')
}

export function getMessageByURL (messageURL: string, published: PublishedState, drafts: DraftsState): Message | DraftMessage {
  return (isDraft(messageURL))
    ? drafts.byURL[messageURL].present.message
    : published.messages[messageURL]
}

export function getShape (
  point: Point | QuotePoint | DraftPoint | DraftQuotePoint,
  published: PublishedState
): PointShape {
  return isQuote(point)
    ? published.points[point.quote.pointURL].shape
    : point.shape
}

export const containsPoints = (
  message: DraftMessage
): boolean => {
  return (
    Object.values(message.shapes).flat().length !== 0 ||
    message.main !== undefined
  )
}

export function removeHyperPrefix (url: string): string {
  if (url.endsWith('/')) return url.slice(URLs.HYPER.length, -1)
  return url.slice(URLs.HYPER.length)
}

export function makeAuthorURL (authorId: string): string {
  return URLs.HYPER + authorId + '/'
}

export function makeMessageURL (authorId: string, messageId: string): string {
  return URLs.HYPER + authorId + '/messages/docs/' + messageId
}

export function capitalizeWord (word: string): string {
  return word[0].toUpperCase() + word.substring(1)
}
