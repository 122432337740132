/*
  Copyright (C) 2021, 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { PointShape } from 'ushin-db'

import './seven-shapes.scss'

import { FactsSvg, ThoughtsSvg, FeelingsSvg, NeedsSvg, TopicsSvg, ActionsSvg, PeopleSvg } from './AllShapes'

interface Props {
  onShapeClick: (shape: PointShape) => void
}

export const SevenShapes = (props: Props): ReactElement => (
  <svg className='seven-shapes' viewBox='0 0 50 52'>
    <g onClick={() => props.onShapeClick('facts')}>
      <FactsSvg />
    </g>
    <g transform='translate(0 16)' onClick={() => props.onShapeClick('thoughts')}>
      <ThoughtsSvg />
    </g>
    <g transform='translate(4 32)' onClick={() => props.onShapeClick('feelings')}>
      <FeelingsSvg />
    </g>
    <g transform='translate(17 36)' onClick={() => props.onShapeClick('needs')}>
      <NeedsSvg />
    </g>
    <g transform='translate(29.5 32.5)' onClick={() => props.onShapeClick('topics')}>
      <TopicsSvg />
    </g>
    <g transform='translate(34 16)' onClick={() => props.onShapeClick('actions')}>
      <ActionsSvg />
    </g>
    <g transform='translate(34 0)' onClick={() => props.onShapeClick('people')}>
      <PeopleSvg />
    </g>
  </svg>
)
