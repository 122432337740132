/*
   Copyright (C) 2022 by USHIN, Inc.

   This file is part of U4U.

   U4U is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.

   U4U is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.

   You should have received a copy of the GNU Affero General Public License
   along with U4U.  If not, see <https://www.gnu.org/licenses/>.
 */
import React, { ReactElement } from 'react'
import { helpers } from 'ushin-db'
import { Link, useSearchParams } from 'react-router-dom'
import { Pencil, Search, People } from 'react-bootstrap-icons'

import './profile-page.scss'

import { setBooleanSearchParams } from '../utils/searchParamsHelpers'
import { removeHyperPrefix } from '../dataModels/pointUtils'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { openEditIdentityModal } from '../slices/modal'
import { setSearchAsPeerURL } from '../slices/search'

import { Banner } from './Banner'

interface Props {
  authorURL: string
}

export const ProfilePageHeader = ({ authorURL }: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const currentIdentity = useAppSelector(({ authors }) => authors.currentIdentity)

  return (
    <div className='profile-page-header'>
      <Banner authorURL={authorURL} />
      {currentIdentity === authorURL &&
        <button className='button' onClick={() => dispatch(openEditIdentityModal())} title='Edit name/color'>
          <Pencil className='bi' />
        </button>}
      <Link to={{
        pathname: `/${removeHyperPrefix(authorURL)}/peer-graph`,
        search: (() => {
          setBooleanSearchParams(searchParams, helpers.SOURCE, true)
          setBooleanSearchParams(searchParams, helpers.BLOCKER, true)
          setBooleanSearchParams(searchParams, helpers.BLOCKED, true)
          setBooleanSearchParams(searchParams, 'shortestpath', true)
          return searchParams.toString()
        })()
      }}
      >
        <button className='button' title='Peer graph'>
          <People className='bi' />
        </button>
      </Link>
      <button className='button' onClick={() => dispatch(setSearchAsPeerURL({ url: authorURL }))} title='Search as this peer'>
        <Search className='bi' />
      </button>
    </div>
  )
}
