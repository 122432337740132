import { StateWithHistory } from 'redux-undo'
import { PayloadAction } from '@reduxjs/toolkit'
import { draftPointCreate, autoDeleteEmptyPoint } from '../slices/drafts'
import { DraftState } from '../slices/draft'

let justCreated = false
export function customFilter (action: PayloadAction<any>, currState: DraftState, prevHistory: StateWithHistory<DraftState>): boolean {
  let unfiltered = true
  if (draftPointCreate.match(action)) {
    // Ignore point creation when traversing undo history
    justCreated = true
    unfiltered = false
  } else if (autoDeleteEmptyPoint.match(action) && justCreated) {
    // Ignore autoDeleteEmptyPoint action which occur right after draftPointCreate when traversing undo history
    // If we click in a region, creating a point, then click away, deleting the point, ignore both actions
    justCreated = unfiltered = false
  } else {
    justCreated = false
  }
  return unfiltered
}
