/*
  Copyright (C) 2020-22 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DragContext {
  index: number
  region: string
}

interface DragState {
  context?: DragContext
  pointURL?: string
}

const initialDragState: DragState = {}

const dragSlice = createSlice({
  name: 'drag',
  initialState: initialDragState,
  reducers: {
    beginDrag: (state, action: PayloadAction<{
      pointURL: string
    }>) => {
      state.pointURL = action.payload.pointURL
    },

    hoverOver: (state, action: PayloadAction<{
      index: number
      region: string
    }>) => {
      state.context = {
        index: action.payload.index,
        region: action.payload.region
      }
    },

    endDrag: (state) => {
      delete state.context
      delete state.pointURL
    }
  }
})

export const {
  beginDrag,
  hoverOver,
  endDrag
} = dragSlice.actions
export default dragSlice.reducer
