/*
  Copyright (C) 2020 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.

  You should have received a copy of the GNU General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { ReactElement } from 'react'
import { PointShape } from 'ushin-db'

import './all-shapes.scss'

export const FactsSvg = (): ReactElement => <path d='M 2,2 14,2 14,14 2,14 Z' />
export const ThoughtsSvg = (): ReactElement => <circle cx='8' cy='8' r='6.25' />
export const FeelingsSvg = (): ReactElement => <path d='M 8,4 A 2 2 0 0 1 14 8 L 8,14 L 2,8 A 2 2 0 0 1 8 4' />
export const NeedsSvg = (): ReactElement => <path d='M 8,2 L 14,14 L 2,14 Z' />
export const TopicsSvg = (): ReactElement => <path d='M 2,2.5 L 14,2.5 L 11,14 L 5,14 Z' />
export const ActionsSvg = (): ReactElement => <path d='M 2,3 H 8 V 1 L 14,8 L 8,15 V 13 H 2 Z' />
export const PeopleSvg = (): ReactElement => <path d='M 2,3 Q 5,1 8,3 T 14,3 L 14,13 Q 11,15 8,13 T 2,13 Z' />

interface Props {
  shape: PointShape
}

export const AllShapes = React.forwardRef<SVGSVGElement, Props>(({ shape }, ref) => (
  <svg className='shape-svg' viewBox='0 0 16 16' ref={ref}>
    {shape === 'facts' && <FactsSvg />}
    {shape === 'thoughts' && <ThoughtsSvg />}
    {shape === 'feelings' && <FeelingsSvg />}
    {shape === 'needs' && <NeedsSvg />}
    {shape === 'topics' && <TopicsSvg />}
    {shape === 'actions' && <ActionsSvg />}
    {shape === 'people' && <PeopleSvg />}
  </svg>
))
